import { Size } from "@clockwise/design-system/src/constants/types";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { chipAsNode } from "../ChatInputChip";
import { createLinkOptionIcon, rescheduleOptionIcon, scheduleOptionIcon } from "../Icons";

export const ChatActions = [
  {
    appendText: "@",
    autoAppendText: true,
    iconOption: scheduleOptionIcon,
    label: "Schedule",
    matchers: ["schedule @", "schedule"],
    selectIndex: 0,
    submitLabel: "Schedule",
    type: "schedule",
    keyboardShortcut: "S",
    includeMultiCalSelection: true,
  },
  {
    appendText: "#",
    autoAppendText: true,
    iconOption: rescheduleOptionIcon,
    label: "Reschedule",
    matchers: ["reschedule #", "reschedule @", "reschedule"],
    selectIndex: 1,
    submitLabel: "Reschedule",
    type: "reschedule",
    keyboardShortcut: "R",
  },
  {
    appendText: "@",
    autoAppendText: true,
    label: "Find time with",
    matchers: ["find time with @", "find time with"],
    submitLabel: "Find time",
    type: "find-time",
  },
  {
    appendText: "",
    iconOption: createLinkOptionIcon,
    label: "Create link",
    matchers: ["create link"],
    selectIndex: 2,
    submitLabel: "Create link",
    type: "create-link",
    keyboardShortcut: "A",
  },
  {
    appendText: "for 30 minutes",
    label: "Create link",
    matchers: ["create a 30-minute scheduling link"], // matches nux button propmt
    submitLabel: "Create link",
    type: "create-link",
  },
];

export type ChatAction = typeof ChatActions[0];

type Props = {
  ref: React.RefObject<HTMLDivElement>;
  onSelect?: () => void;
  commandBarEnabled?: boolean; // if true, options are active by default without the need to type a command symbol (`/`)
  size?: Size;
};

export const useChatActionChip = ({
  ref,
  onSelect,
  commandBarEnabled = false,
  size = "mini",
}: Props) => {
  const [modalOptionsActive, setModalOptionsActive] = useState<boolean>(false);
  const [action, setAction] = useState<ChatAction | null>(null);
  const [search, setSearch] = useState<string>("");

  const options = useMemo(() => {
    if (!modalOptionsActive && !commandBarEnabled) {
      return [];
    }
    if (action !== null) {
      return [];
    }

    const chatActionOptions = ChatActions.filter((action) => action.selectIndex !== undefined).sort(
      (a1, a2) => {
        if (a1.selectIndex === a2.selectIndex) {
          return 0;
        }
        return a1.selectIndex! > a2.selectIndex! ? 1 : -1;
      },
    );

    if (search === "") {
      return chatActionOptions;
    }

    return chatActionOptions.filter((action) =>
      action.label.toLowerCase().startsWith(search?.toLowerCase() || ""),
    );
  }, [modalOptionsActive, search, commandBarEnabled, action]);

  const removeChipsFromRef = useCallback(() => {
    const input = ref.current;
    if (!input) {
      return;
    }

    input.childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (element.dataset.chip_type === "action") {
          element.remove();
        }
      }
    });
  }, [ref]);

  const insertChipToRef = useCallback(
    (action: ChatAction) => {
      if (action) {
        chipAsNode(
          {
            chipType: "action",
            size: size,
            text: action.label,
            variant: "default",
          },
          (node) => {
            if (ref.current && node) {
              ref.current.insertBefore(node, ref.current.firstChild);

              if (action.appendText) {
                let text = action.appendText;
                if (!node.nextSibling?.textContent?.endsWith(" ")) {
                  const nbsp = String.fromCharCode(160);
                  text = `${nbsp}${text}`;
                }
                const textNode = document.createTextNode(text);
                ref.current.insertBefore(textNode, node.nextSibling);
              }
            }
          },
        );
      }
    },
    [ref],
  );

  const removeSearchTextFromRef = useCallback(() => {
    const input = ref.current;

    if (!input) {
      return;
    }

    input.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textNode = node as Text;
        if (
          textNode.textContent?.startsWith(`/${search}`) ||
          (commandBarEnabled && !!search && textNode.textContent?.startsWith(search))
        ) {
          textNode.remove();
        }
      }
    });
  }, [ref, search, commandBarEnabled]);

  const removeMatchFromRef = useCallback(
    (match: string) => {
      const input = ref.current;

      if (!input) {
        return;
      }

      const firstChild = input.childNodes[0];
      if (firstChild && firstChild.nodeType === Node.TEXT_NODE) {
        const textNode = firstChild as Text;
        const caseInsensitiveMatch = new RegExp(match, "i");
        textNode.textContent =
          textNode?.textContent?.replace(caseInsensitiveMatch, "").trim() || "";
      }
    },
    [ref],
  );

  const handleSelect = useCallback(
    (action: ChatAction | null) => {
      setAction(action);
      removeChipsFromRef();
      removeSearchTextFromRef();
      if (action) {
        insertChipToRef(action);
      }
      setSearch("");
      setModalOptionsActive(false);
      onSelect?.();
    },
    [insertChipToRef, onSelect, removeChipsFromRef, removeSearchTextFromRef],
  );

  const handleCancel = useCallback(() => {
    setAction(null);
    setSearch("");
    removeSearchTextFromRef();
  }, [removeSearchTextFromRef]);

  const handleSearch = useCallback(
    ({ modalOptionsActive, search }: { modalOptionsActive: boolean; search: string }) => {
      setSearch(search);
      setModalOptionsActive(modalOptionsActive);
    },
    [],
  );

  const handleRemove = useCallback(({ element: _ }: { element: HTMLElement }) => {
    setAction(null);
  }, []);

  const evalInputAction = useCallback(
    (input: HTMLDivElement | null, chipify: boolean) => {
      if (!input) {
        handleSearch({ modalOptionsActive: false, search: "" });
        return;
      }

      const trimmedText = input.innerText.toLowerCase().trim();
      const startsWithCommandSymbol = trimmedText.startsWith("/");

      if (startsWithCommandSymbol) {
        const search = trimmedText.slice(1) || "";
        handleSearch({ modalOptionsActive: true, search });
        return;
      }

      const firstChild = input.childNodes[0];
      if (chipify && firstChild && firstChild.nodeType === Node.TEXT_NODE) {
        const textNode = firstChild as Text;
        const text = textNode.textContent || "";
        const [newAction, removeText] = findMatchedAction(text);
        if (newAction) {
          removeMatchFromRef(removeText);
          handleSelect(newAction);
          handleSearch({ modalOptionsActive: false, search: "" });
          return;
        }
      }
      handleSearch({ modalOptionsActive: false, search: trimmedText });
    },
    [handleSearch, handleSelect, removeMatchFromRef],
  );

  return {
    chatAction: action,
    chatActionOptions: options,
    evalInputAction,
    onActionRemoved: handleRemove,
    onCancelChatAction: handleCancel,
    onSelectChatAction: handleSelect,
    searchSymbol: isEmpty(options) ? undefined : ("/" as const),
  };
};

const findMatchedAction = (text?: string): [ChatAction, string] | [null, null] => {
  if (!text) return [null, null];

  for (const action of ChatActions) {
    for (const matcher of action.matchers) {
      if (text.toLowerCase().replaceAll("&nbsp;", " ").trimStart().startsWith(matcher)) {
        return [action, matcher];
      }
    }
  }

  return [null, null];
};
