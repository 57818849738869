import {
  generateRescheduleCQL,
  generateScheduleCQL,
} from "@clockwise/web-commons/src/util/generateCQL";
import { ChatAction } from "../hooks/useChatActionChip";

export const generateChatActionCQL = ({
  action,
  calendarIds = [],
  externalEventId,
  duration,
}: {
  action: ChatAction["type"];
  calendarIds?: string[];
  externalEventId?: string;
  duration?: number;
}) => {
  switch (action) {
    case "schedule":
    case "find-time":
      return generateScheduleCQL(calendarIds, duration);
    case "reschedule":
      return generateRescheduleCQL(externalEventId);
    case "create-link":
      // not supported by cql at present
      return null;
    default:
      return null;
  }
};
