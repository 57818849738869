import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EventCardAttendee } from "../../event-card/types";
import { useOrgId } from "../../hooks/useOrgId";
import { useSetAIError } from "../ai-chat/hooks/AIErrorContext";
import { Collaborator, useCollaborators } from "../ai-chat/hooks/useCollaborators";
import {
  SearchAttendeesDocument,
  SearchAttendeesQuery,
} from "../apollo/__generated__/SearchAttendees.v2.generated";

type UseSearchPersonsChatResult = {
  searchPersons: EventCardAttendee[];
  handlePersonSearch: (searchQuery: string, hiddenCalendars: string[]) => void;
  resetSearch: () => void;
  setShowEmptyState: (emptyState: boolean) => void;
};
type SearchMember = Extract<SearchAttendeesQuery["searchAttendees"][0], { __typename: "Member" }>;

const convertOrgPersonToEventCardAttendee = (collaborator: Collaborator): EventCardAttendee => {
  return {
    primaryEmail: collaborator?.calendarId || "",
    primaryCalendar: collaborator?.calendarId || "",
    id: collaborator?.calendarId || "",
    userId: null,
    isYou: false, // will always be false
    profile: {
      givenName: collaborator?.fullName || null,
      familyName: null,
      externalImageUrl: collaborator?.externalImageUrl || null,
    },
  };
};

export const useSearchPersonsChat = ({
  searchPersonLimit = 5,
  skip,
  showAllTopCollaborators = false,
}: {
  searchPersonLimit?: number;
  skip?: boolean;
  showAllTopCollaborators?: boolean;
}): UseSearchPersonsChatResult => {
  const { topCollaborators } = useCollaborators();

  const { orgId } = useOrgId();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchPersons, setSearchPersons] = useState<EventCardAttendee[]>([]);
  const [hiddenCalendars, setHiddenCalendars] = useState<string[]>([]);
  const [showTopCollaborators, setShowTopCollaborators] = useState<boolean>(false);

  const setError = useSetAIError();

  const isEmptyQuery = isEmpty(searchQuery.trim());
  const { data } = useGatewayQuery(SearchAttendeesDocument, {
    variables: {
      searchQuery,
      limit: searchPersonLimit,
      excludedCalendars: hiddenCalendars,
    },
    skip: skip || !orgId || isEmptyQuery,
    fetchPolicy: "no-cache",
    onError: (error) => {
      setError({ error, message: "Failed to fetch searchPersons", showUserMessage: false });
    },
  });

  const topCollaboratorsAsEventCardAttendees = useMemo(() => {
    const collaborators: EventCardAttendee[] = [];
    const topCollaboratorsToConvert = showAllTopCollaborators
      ? topCollaborators
      : topCollaborators.slice(0, 4);
    topCollaboratorsToConvert.forEach((collaborator) => {
      if (collaborator) {
        collaborators.push(convertOrgPersonToEventCardAttendee(collaborator));
      }
    });
    return collaborators;
  }, [topCollaborators, showAllTopCollaborators]);

  useEffect(() => {
    if (showTopCollaborators) {
      setSearchPersons(topCollaboratorsAsEventCardAttendees);
    }
  }, [showTopCollaborators, topCollaboratorsAsEventCardAttendees]);

  const setShowEmptyState = useCallback(
    (emptyState: boolean) => {
      setShowTopCollaborators(emptyState);
    },
    [setShowTopCollaborators, showAllTopCollaborators],
  );

  const handlePersonSearch = useMemo(() => {
    const debouncedSearch = debounce((newSearchQuery: string, hiddenCalendars?: string[]) => {
      setSearchQuery(newSearchQuery);
      setHiddenCalendars(hiddenCalendars || []);
    }, 20);

    return (newSearchQuery: string, hiddenCalendars?: string[]) => {
      void debouncedSearch(newSearchQuery, hiddenCalendars);
    };
  }, []);
  const resetSearch = useCallback(() => {
    setSearchQuery("");
    setSearchPersons([]);
    setShowTopCollaborators(false);
  }, []);

  useEffect(() => {
    if (!data) return;

    // Find results that are of type Member for now
    // Transform to legacy EventCardAttendee format
    const personsFromData = data.searchAttendees
      .filter((attendee) => attendee.__typename === "Member")
      .map((searchPerson: SearchMember) => {
        const { person } = searchPerson;
        return {
          id: person.id,
          primaryEmail: person.email,
          userId: null, // We don't seem to need userId anywhere?
          isYou: person.isMe,
          primaryCalendar: person.email,
          profile: {
            givenName: person.givenName,
            familyName: person.familyName,
            externalImageUrl: person.externalImageUrl,
          },
        };
      });

    setSearchPersons(personsFromData);
  }, [data]);

  return useMemo(() => {
    return {
      searchPersons,
      handlePersonSearch,
      resetSearch,
      setShowEmptyState,
      showTopCollaborators,
    };
  }, [searchPersons, handlePersonSearch, resetSearch, setShowEmptyState, showTopCollaborators]);
};
