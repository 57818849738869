import { useMutation } from "@apollo/client";
import { MentionsV2 } from "@clockwise/schema";

import { useUpdateProposalOptionsOverlay } from "#webapp/src/components/chat-plus-calendar/util/ProposalOptionsOverlayContext";
import { logger } from "@clockwise/client-commons/src/util/logger";
import { ProcessMessageGCalMutation } from "@clockwise/web-commons/src/mutations/__generated__/ProcessMessageGCal.generated";
import { useUpdateHighlightEvents } from "@clockwise/web-commons/src/util/HighlightEventsContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { getMostRecentConversationId } from "@clockwise/web-commons/src/util/getMostRecentConversationId";
import { last } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setLoadingProposalUIInFlight,
  setProcessChatMessageUIDone,
  setProcessChatMessageUIInFlight,
} from "../../../../state/actions/ui-flow-on-screen.actions";
import { useFullStory } from "../../../../util/fullstory";
import { ProcessMessageDocument } from "../apollo/__generated__/ProcessMessage.generated";
import { tempAssistantMessage } from "../utils/getTempAssistantMessage";
import { getTempUserMessage } from "../utils/getTempUserMessage";
import { useSetAIError } from "./AIErrorContext";
import { useAIMessageContext } from "./AIMessageContext";
import { useScrollChatToBottom } from "./useScrollChatToBottom";

export type ProcessMessage = (
  text: string,
  mentions: MentionsV2,
  cql?: string | null,
) => Promise<void>;

export const useProcessMessage = (onCompletedCallback?: (timeout?: number) => void) => {
  const {
    addDebugInfo,
    setProcessingMessage,
    chatHistoryId,
    loadingHistory,
    messages,
    setCancelProcessing,
    cancelProcessing,
    shouldExcludeAi,
  } = useAIMessageContext();
  const setHighlightEvents = useUpdateHighlightEvents();
  const { track: fsTrack } = useFullStory();
  const track = useTracking();
  const setError = useSetAIError();
  const scrollChatToBottom = useScrollChatToBottom();
  const dispatch = useDispatch();
  const setProposalOptionsOverlayVisibility = useUpdateProposalOptionsOverlay();

  const abortController = useRef(new AbortController());

  // This stops the request completely
  const abort = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  useEffect(() => {
    // If cancelProcessing, abort the process message request
    if (cancelProcessing) {
      abort();
      setCancelProcessing(false);
    }
  }, [cancelProcessing]);

  const [processMessageData, setProcessMessageData] = useState<ProcessMessageGCalMutation | null>(
    null,
  );

  const [processMessageMutation] = useMutation(ProcessMessageDocument, {
    context: {
      headers: {
        "x-clockwise-debug-trace": true,
      },
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },

    onCompleted: (data) => {
      setCancelProcessing(false);
      const updatedMessages = data?.processMessage?.updatedHistory.messages ?? [];
      const assistantResponse = last(updatedMessages);

      const debugInfo = data?.processMessage?.debugInfo;
      const conversationId = data?.processMessage?.conversationId;

      if (assistantResponse) {
        if (assistantResponse.__typename === "ViewEventsResponse") {
          setHighlightEvents(assistantResponse.eventIds);
        }
        const fsUrl = fsTrack(TrackingEvents.CHAT.CHAT_MESSAGE_PROCESSING_SUCCESS, {
          hasProposal: assistantResponse.__typename === "ProposalResponse",
          conversationId,
        });
        track(TrackingEvents.CHAT.CHAT_MESSAGE_PROCESSING_SUCCESS, {
          hasProposal: assistantResponse.__typename === "ProposalResponse",
          conversationId,
          fsUrl,
        });

        if (debugInfo) {
          addDebugInfo(assistantResponse.id, debugInfo);
        }

        if (assistantResponse.__typename === "ProposalResponse") {
          // We will need to end the message processing once the calendars are loaded in
          // somewhere else in the app
          dispatch(setLoadingProposalUIInFlight());
          dispatch(setProcessChatMessageUIDone());
        } else {
          dispatch(setProcessChatMessageUIDone());
        }
      }
      onCompletedCallback?.();
      scrollChatToBottom();
      setProcessingMessage(false);
      setProcessMessageData(data);
      setProposalOptionsOverlayVisibility(true);
    },
    onError: (error) => {
      const previousMessages = messages;
      const mostRecentConversationId = getMostRecentConversationId(
        processMessageData,
        previousMessages,
      );

      // if user intentionally cancels the message, don't throw an error
      if (error.message === "The user aborted a request.") {
        setCancelProcessing(false);
        track(TrackingEvents.CHAT.CANCEL.PROCESS_MESSAGE_ERROR);
        return;
      }

      let errMessage = "Failed to process chat message";
      const timeoutErr = error.message.includes("ESOCKETTIMEDOUT");
      if (timeoutErr) {
        errMessage += " due to a timeout. Please try again later.";
      }

      const fsUrl = fsTrack(TrackingEvents.CHAT.CHAT_MESSAGE_PROCESSING_FAILURE, {
        timeoutErr,
        conversationId: mostRecentConversationId,
      });
      track(TrackingEvents.CHAT.CHAT_MESSAGE_PROCESSING_FAILURE, {
        timeoutErr,
        conversationId: mostRecentConversationId,
        fsUrl,
      });
      setError({
        error,
        message: "Failed to process chat message",
        userMessage: errMessage,
        showUserMessage: true,
      });

      setProcessingMessage(false);
    },
  });

  const canProcessMessage = !shouldExcludeAi;

  const processMessage: ProcessMessage = async (text, mentions, cql) => {
    if (!canProcessMessage) {
      if (shouldExcludeAi) {
        // this could indicate features that should be disabled in the UI but are not
        logger.error("Attempted to process message for excluded organization");
      }
      // return preventing mutation from being called
      return Promise.resolve();
    }

    dispatch(setProcessChatMessageUIInFlight());

    const trimmedText = text.trim();
    const userMessage = getTempUserMessage(trimmedText);
    const assistantMessage = tempAssistantMessage;

    const debugInfo = "";
    const conversationId = "";
    setProcessingMessage(true);

    track(TrackingEvents.CHAT.BEGIN_PROCESS_CHAT_MESSAGE, { text: trimmedText });

    const appendOptimisticMessage = chatHistoryId && !loadingHistory;

    if (!appendOptimisticMessage) {
      track("ProcessMessageMutation: Unable to append optimistic message", {
        chatHistoryId,
        loadingHistory,
      });
    }

    scrollChatToBottom();
    await processMessageMutation({
      variables: { input: { text: trimmedText, mentionsV2: mentions, cql } },
      optimisticResponse: appendOptimisticMessage
        ? {
            __typename: "Mutation",
            processMessage: {
              __typename: "ProcessMessagePayload",
              debugInfo,
              conversationId,
              updatedHistory: {
                __typename: "ChatHistory",
                id: chatHistoryId,
                shouldExcludeAi,
                isProcessing: false,
                messages: [...messages, userMessage, assistantMessage],
              },
            },
          }
        : undefined,
    });
  };

  return {
    processMessage,
    canProcessMessage,
  };
};
