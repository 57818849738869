import { noop } from "lodash";
import React, { createContext, ReactNode, useContext, useState } from "react";
import invariant from "tiny-invariant";

type EventId = string;

const ReadContext = createContext<EventId[]>([]);
const WriteContext = createContext(noop);

export const HighlightEventsProvider = ({
  children,
  initValue = [],
}: {
  children: ReactNode;
  initValue?: EventId[];
}) => {
  const [highlightEvents, setHighlishtEvents] = useState<EventId[]>(initValue);

  return (
    <WriteContext.Provider value={setHighlishtEvents}>
      <ReadContext.Provider value={highlightEvents}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadHighlightEvents = () => useContext(ReadContext);

export const useUpdateHighlightEvents = () => {
  const context = useContext(WriteContext);
  invariant(context, "useUpdateHighlightEvents must be within HighlightEventsProvider");
  return context;
};

export const useRemoveHighlightEvent = () => {
  const setHighlishtEvents = useContext(WriteContext);
  invariant(setHighlishtEvents, "useRemoveHighlightEvent must be within HighlightEventsProvider");

  return (eventId: string) =>
    setHighlishtEvents((ids: string[]) => ids.filter((id) => id === eventId));
};
