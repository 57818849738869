import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { useGatewayLazyQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getAnalyticsId, shouldTrack } from "@clockwise/web-commons/src/util/analytics.util";
import * as FullStory from "@fullstory/browser";
import { noop } from "lodash";
import { useCallback } from "react";
import { useFeatureFlag } from "../launch-darkly";
import { FullStoryUserDocument } from "./__generated__/FullStoryUser.v2.generated";

const FULLSTORY_ORG_ID = "o-1QKH15-na1";

const initFullStoryAndIdentify = (userProperties: FullStory.UserVars) => {
  FullStory.init({ orgId: FULLSTORY_ORG_ID }, () => {
    FullStory.identify(getAnalyticsId(), userProperties);
  });
};

const track = (
  eventName: string,
  eventProperties: {
    [key: string]: unknown;
  },
) => {
  FullStory.event(eventName, eventProperties);
  const playbackAtThisMomentInTime = FullStory.getCurrentSessionURL(true);

  return playbackAtThisMomentInTime;
};

export const useFullStory = () => {
  const isProd = getEnvironment() === "production";
  const [disableFullStory] = useFeatureFlag("DisableFullStory");
  const [fetchCurrentUser] = useGatewayLazyQuery(FullStoryUserDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const identify = useCallback(async () => {
    const { data } = await fetchCurrentUser();
    const displayName = data?.currentUser?.member.person.displayName;
    const email = data?.currentUser?.member.person.email;

    initFullStoryAndIdentify({ displayName, email });
  }, [fetchCurrentUser]);

  if (!isProd || !shouldTrack() || disableFullStory) {
    return {
      identify: () => noop(),
      track: noop,
    };
  }

  return {
    identify,
    track,
  };
};
