import { noop } from "lodash";
import React, { ReactNode, createContext, useContext } from "react";
import invariant from "tiny-invariant";

const ReadContext = createContext<boolean>(false);
const WriteContext = createContext<(isVisible: boolean) => void>(noop);

type ProviderProps = {
  children: ReactNode;
  initValue: boolean;
};

export type ProposalOptionsOverlayVariant = "rail" | "default";

export const ProposalOptionsOverlayProvider = ({ children, initValue }: ProviderProps) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(initValue);

  return (
    <WriteContext.Provider value={setIsVisible}>
      <ReadContext.Provider value={isVisible}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadProposalOptionsOverlay = () => useContext(ReadContext);

export const useUpdateProposalOptionsOverlay = () => {
  const context = useContext(WriteContext);
  invariant(
    context,
    "useUpdateProposalOptionsOverlay must be within ProposalOptionsOverlayProvider",
  );
  return context;
};
