import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const RescheduleAI = (args: SvgIconProps) => (
  <SvgIcon {...args}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_48_14849)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 6.33341V2.80675C14 2.50675 13.64 2.36008 13.4333 2.57341L12.2466 3.76008C11.04 2.55341 9.31996 1.86008 7.43996 2.02675C4.64663 2.28008 2.34663 4.52675 2.03996 7.32008C1.77394 9.72318 2.93215 11.8845 4.7792 13.0627C5.0804 13.2548 5.47144 13.1328 5.64769 12.8221C5.83881 12.4851 5.7031 12.0598 5.38475 11.839C4.17524 11.0003 3.35818 9.60965 3.3333 8.06675C3.2933 5.46675 5.40663 3.33341 7.99996 3.33341C9.28663 3.33341 10.4533 3.86008 11.3 4.70008L9.90663 6.09341C9.6933 6.30675 9.83996 6.66675 10.14 6.66675H13.6666C13.8533 6.66675 14 6.52008 14 6.33341ZM7.33346 8.28626V5.83293C7.33346 5.55959 7.56012 5.33293 7.83346 5.33293C8.10679 5.33293 8.33346 5.55959 8.34012 5.82626V8.09293L10.2535 9.23293C10.4935 9.37293 10.5668 9.67959 10.4268 9.91959C10.2868 10.1529 9.98012 10.2329 9.74012 10.0929L7.66012 8.85959C7.46012 8.73959 7.33346 8.51959 7.33346 8.28626ZM13.2935 11.9529L12.6668 13.3329L12.0535 11.9529L10.6668 11.3329L12.0535 10.7129L12.6668 9.33293L13.2935 10.7129L14.6668 11.3329L13.2935 11.9529ZM8.33346 14.6663L8.66679 13.3329L10.0001 12.9996L8.66679 12.6663L8.33346 11.3329L8.00012 12.6663L6.66679 12.9996L8.00012 13.3329L8.33346 14.6663Z"
          fill={args.fill || "#575757"}
        />
      </g>
      <defs>
        <clipPath id="clip0_48_14849">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
