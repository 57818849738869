import { BOTTOM_OF_CHAT_NODE_ID } from "../AIChatBox";

export const useScrollChatToBottom = () => {
  const scrollChatToBottom = (timeout?: number) => {
    setTimeout(() => {
      const bottomOfChat = document.getElementById(BOTTOM_OF_CHAT_NODE_ID);

      if (!bottomOfChat) return;

      bottomOfChat.scrollIntoView({ behavior: "smooth", block: "end" });
    }, timeout ?? 50);
  };

  return scrollChatToBottom;
};
