import { Event } from "@clockwise/design-system/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { SearchEvent } from "../../../hooks/useEventsSearch";
import { formatEventMentionStartTime } from "../../../util/formatEventMentionStartTime";
import { useKeyboardSelect } from "./hooks/useKeyboardSelect";

type Props = {
  inputRef: React.RefObject<HTMLDivElement>;
  onSelect: (event: SearchEvent, fromMouseEvent: boolean) => void;
  options: SearchEvent[];
};

export const EventSelect = ({ inputRef, onSelect, options }: Props) => {
  const handleSelect = useCallback(
    (index: number, fromMouseEvent: boolean) => {
      onSelect(options[index], fromMouseEvent);
    },
    [onSelect, options],
  );

  const isActive = !isEmpty(options);

  const onKeyboardSelect = useCallback((index: number) => handleSelect(index, false), [
    handleSelect,
  ]);

  const onMouseSelect = useCallback(
    (index: number) => {
      handleSelect(index, true);
    },
    [handleSelect],
  );

  const { activeIndex, setActiveIndex } = useKeyboardSelect({
    active: isActive,
    inputRef,
    onSelect: onKeyboardSelect,
    optionCount: options.length,
  });

  useEffect(() => {
    // Reset index to top on search change
    setActiveIndex(0);
  }, [options, setActiveIndex]);

  return (
    <>
      {options.map((option, index) => (
        <EventSelectOption
          highlight={index === activeIndex}
          key={`${option.externalEventId}-${index}`}
          onMouseDown={() => onMouseSelect(index)}
          onMouseEnter={() => setActiveIndex(index)}
          option={option}
        />
      ))}
    </>
  );
};

type OptionProps = {
  highlight: boolean;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  option: Props["options"][0];
};

export const EventSelectOption = ({
  highlight,
  onMouseDown,
  onMouseEnter,
  option,
}: OptionProps) => {
  return (
    <div
      tabIndex={0}
      onMouseEnter={onMouseEnter}
      className={classNames(
        "cw-p-1 cw-cursor-pointer cw-flex cw-items-center cw-justify-between cw-space-x-5 cw-rounded cw-mb-1",
        { "cw-bg-default-hover": highlight },
      )}
      onMouseDown={onMouseDown}
    >
      <div className="cw-flex cw-items-center cw-overflow-hidden">
        <Event className="cw-text-muted cw-w-4 cw-h-4 cw-mx-1" />
        <span className="cw-font-medium cw-body-sm  cw-ml-2 cw-mr-1 cw-text-ellipsis cw-whitespace-nowrap cw-overflow-hidden">
          {option.title}
        </span>
      </div>
      <span className="cw-text-neutral-muted cw-body-sm cw-text-xs cw-text-end">
        {formatEventMentionStartTime(option.startTime)}
      </span>
    </div>
  );
};
