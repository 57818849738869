import { EventMention } from "@clockwise/schema";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import { EventCardAttendee } from "../../event-card/types";
// This function is used to get the index the current row the user is on.
// Here we grab the selection's nodeValue (AKA the text in the row)
// Used to watch for whether a mention is being typed
const getCaretIndexWithRowText = () => {
  let currentTextOfSelectionRow = "";
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection) {
      // Check if there is a selection (i.e. cursor in place)
      if (selection.rangeCount !== 0) {
        currentTextOfSelectionRow = selection.anchorNode?.nodeValue || "";
      }
    }
  }
  return { text: currentTextOfSelectionRow };
};

const isSpace = (char: string) => char === " " || char === String.fromCharCode(160);

const isNewLine = (char: string) => char === "\n" || char === String.fromCharCode(10);

// Check if you are on a word with an @ in the beginning
// Return the word without the @
export const fetchPotentialMention = (optionalPrompt?: string) => {
  const { text: currentText } = getCaretIndexWithRowText();

  const text = optionalPrompt || currentText;

  if (text.includes("@")) {
    let isEmptyAtSymbol = false;
    let wordToSearch = "";
    let isAMention = false;

    const atIndex = text.lastIndexOf("@");

    if (atIndex === 0) {
      //is not a mention if there is a space or newline direclty after the @
      if (text.length === 0) {
        isEmptyAtSymbol = true;
        isAMention = true;
        wordToSearch = text.slice(atIndex + 1).trim();
      } else {
        const nextChar = text.charAt(atIndex + 1);
        if (!isSpace(nextChar) && !isNewLine(nextChar)) {
          isEmptyAtSymbol = atIndex === text.length - 1;
          isAMention = true;
          wordToSearch = text.slice(atIndex + 1).trim();
        }
      }
    } else if (
      // if the character before the @ is a space, and character after is NOT a space or newline, it's a mention
      isSpace(text.charAt(atIndex - 1)) &&
      !isSpace(text.charAt(atIndex + 1)) &&
      !isNewLine(text.charAt(atIndex + 1))
    ) {
      // empty if it's the last character of the string
      isEmptyAtSymbol = atIndex === text.length - 1;
      isAMention = true;
      wordToSearch = text.slice(atIndex + 1).trim();
    }

    // If there's a newline within the word to search, not a mention
    if (wordToSearch.includes("\n")) {
      wordToSearch = "";
      isAMention = false;
    }
    return {
      isEmptyAtSymbol,
      isAMention,
      wordToSearch,
    };
  }
  return {
    isEmptyAtSymbol: false,
    isAMention: false,
    wordToSearch: "",
  };
};

// Once we select a mention, the caret has a tendency to jump to the beginning of the input. This is put it back at the end.
// TODO: Make this more robust so that it has the caret going to the end of the created mention instead of the end
export const putCaretAtEndOfInput = (element: HTMLDivElement | null) => {
  if (!element) return;
  const sel = window.getSelection();
  const range = document.createRange();
  if (!sel) return;
  sel.removeAllRanges();
  range.selectNodeContents(element);
  range.collapse(false);
  sel.addRange(range);
  element.focus();
};

export const addStyledPersonMentionToInput = (
  inputElement: HTMLDivElement,
  newPerson: EventCardAttendee,
  searchQuery: string,
) => {
  const innerChat = inputElement.innerHTML;
  const mentionBeingAdded = "@" + searchQuery;
  const splitChatByMention = innerChat.split(mentionBeingAdded);
  const nameInPrompt = getFullName(newPerson.profile);
  const { primaryCalendar } = newPerson;
  // We need to base64 encode the primary calendar id because we don't want any collisions with the @-mentions search query
  const primaryCalendarIdB64 = btoa(primaryCalendar);
  // This is the entire input. We are inserting the name and bolding it. Note that we dont want this to be contenteditable
  const newValue = `${
    splitChatByMention[0]
  }<span class="cw-font-semibold" contenteditable="false" data-primary-calendar-b64="${primaryCalendarIdB64}">${nameInPrompt}</span>${
    splitChatByMention?.[1] || "&nbsp;"
  }`;
  // Here we are setting the new html
  inputElement.innerHTML = "";
  inputElement.innerHTML = newValue;
  // Returning the name in the prompt so that we can use it in our network call
  return { name: nameInPrompt, calendarId: newPerson.primaryCalendar };
};

const sanitizeCaretSymbols = (text: string) => text.replaceAll("<", "&lt;").replaceAll(">", "&gt;");

export const addStyledEventMentionToInput = (
  inputElement: HTMLDivElement,
  newEvent: EventMention,
  searchQuery: string,
) => {
  const innerChat = inputElement.innerHTML;
  const mentionBeingAdded = "@" + sanitizeCaretSymbols(searchQuery);
  const splitChatByMention = innerChat.split(mentionBeingAdded);
  const nameInPrompt = sanitizeCaretSymbols(newEvent.title);
  // This is the entire input. We are inserting the name and bolding it. Note that we dont want this to be contenteditable

  const newValue = `${
    splitChatByMention[0]
  }<span class="cw-font-semibold" contenteditable="false" cw-id="eventMention" >${nameInPrompt}</span>${
    splitChatByMention?.[1] || "&nbsp;"
  }`;
  // Here we are setting the new html
  inputElement.innerHTML = "";
  inputElement.innerHTML = newValue;
};

const getWrappingIndex = (adjustment: number) => (length: number) => (currentIndex: number) =>
  currentIndex + adjustment < 0 ? length - 1 : (currentIndex + adjustment) % length;

const incrementWrappingIndex = getWrappingIndex(1);
const decrementWrappingIndex = getWrappingIndex(-1);

// Returns what index of the search results should have the hover/selecting state that highlights the row and preps for selection.
// When null, no row should be highlighted (initial state), 0 is index 0
export const getNewMentionIndex = (
  keyHit: string,
  indexNewMention: number,
  personResultsCount: number,
  eventResultsCount: number,
) => {
  const increment = incrementWrappingIndex(personResultsCount + eventResultsCount);
  const decrement = decrementWrappingIndex(personResultsCount + eventResultsCount);

  switch (keyHit) {
    case "ArrowDown":
      return increment(indexNewMention);
    case "ArrowUp":
      return decrement(indexNewMention);
    default:
      return 0;
  }
};
