import { isImpersonated } from "@clockwise/client-commons/src/util/jwt";
import { MentionsV2 } from "@clockwise/schema";
import { jwt } from "@clockwise/web-commons/src/util/local-storage";
import React, { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import { useDefragProposal } from "../../chat-plus-calendar/DefragProposalContext";
import { AI_EXCLUSION_TOOLTIP, AI_IMPERSONATION_TOOLTIP } from "../constants";
import { useCancelProcessMessage } from "../hooks/useCancelProcessMessage";
import { ChatInputSuggestions } from "../web/ChatInputSuggestions";
import { ChatInput } from "./ChatInput";
import { useAIMessageContext } from "./hooks/AIMessageContext";
import { useFocusChatTextInput } from "./hooks/useFocusChatTextInput";
import { useProcessMessage } from "./hooks/useProcessMessage";
import { useScrollChatToBottom } from "./hooks/useScrollChatToBottom";

export const BOTTOM_OF_CHAT_NODE_ID = "cw-bottom-of-chat";

export const AIChatBox = () => {
  const {
    value: canSeeSuggestionsPrism,
    setTrue: showSuggestions,
    setFalse: hideSuggestions,
  } = useBoolean(false);

  const {
    processingMessage,
    messages,
    debugModel,
    containsActiveProposal,
    loadingHistory,
    shouldExcludeAi,
  } = useAIMessageContext();
  const scrollChatToBottom = useScrollChatToBottom();
  const focusChatTextInput = useFocusChatTextInput();
  const previousMessagesLengthRef = React.useRef(messages.length);
  const { processMessage } = useProcessMessage(focusChatTextInput);
  const cancelProcessing = useCancelProcessMessage(scrollChatToBottom);
  const { onDemandDefrag } = useDefragProposal();

  const isImpersonatedUser = isImpersonated(jwt.get());

  // scroll to bottom if possible on first load
  useEffect(() => {
    if (loadingHistory) {
      // We want to wait until the history is loaded before scrolling to the bottom
      return;
    }

    scrollChatToBottom(600);
  }, [loadingHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  // scroll to bottom if new message loads in from the extension gCal button
  useEffect(() => {
    if (previousMessagesLengthRef.current < messages.length) {
      scrollChatToBottom();
    }

    previousMessagesLengthRef.current = messages.length;
  }, [previousMessagesLengthRef.current, messages.length]);

  const submitMessage = async (
    currentText: string,
    mentions: MentionsV2,
    cql?: string | null | undefined,
  ) => {
    scrollChatToBottom();
    let submitText = currentText;
    if (debugModel !== "default") {
      submitText = `[${debugModel}] ${currentText}`;
    }
    void processMessage(submitText, mentions, cql);
  };

  const cancelProcessMessage = () => {
    void cancelProcessing();
  };

  const focusInput = messages.length === 0 && !processingMessage;
  const showChatInputSuggestions = !processingMessage && !shouldExcludeAi && canSeeSuggestionsPrism;

  if (onDemandDefrag.visible) {
    return null;
  }

  return (
    <>
      <div
        onMouseOver={showSuggestions}
        onMouseOut={hideSuggestions}
        className="cw-justify-self-end cw-pt-8 cw-sticky cw-bottom-0 cw-bg-gradient-to-t cw-from-[#FAFAFA] cw-to-transparent"
      >
        {showChatInputSuggestions && <ChatInputSuggestions />}
        <div className="cw-px-4 cw-w-full cw-pb-4">
          <ChatInput
            disabled={isImpersonatedUser || shouldExcludeAi}
            disabledReason={
              isImpersonatedUser
                ? AI_IMPERSONATION_TOOLTIP
                : shouldExcludeAi
                ? AI_EXCLUSION_TOOLTIP
                : undefined
            }
            focusInput={focusInput}
            hasActiveProposal={containsActiveProposal}
            loading={processingMessage}
            onCancel={cancelProcessMessage}
            onSubmit={submitMessage}
            onFocusCallback={showSuggestions}
          />
        </div>
      </div>
      <span id={BOTTOM_OF_CHAT_NODE_ID} />
    </>
  );
};
