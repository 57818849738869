import { Kbd } from "@clockwise/design-system";
import {
  Button,
  COLOR_CLASSES_HASH,
  Size,
  SIZE_CLASSES,
} from "@clockwise/design-system/src/components/Button";
import { helpDefaultWindowName } from "@clockwise/web-commons/src/constants/route.constants";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const helpCenterLink = "https://support.getclockwise.com/collection/197-prism";

type Props = {
  buttonSize: Size;
  onSelect: () => void;
  selectedOptionLabel: string;
};

export const CommandBarActionSelectFooter = ({
  buttonSize,
  onSelect,
  selectedOptionLabel,
}: Props) => {
  return (
    <div
      className={classNames(
        "cw-flex cw-gap-3 cw-justify-between",
        "cw-py-2 cw-px-4",
        "cw-text-13 cw-text-muted cw-font-medium",
        "cw-border-t cw-border-solid cw-border-subtle",
      )}
    >
      <Link
        className={classNames(
          COLOR_CLASSES_HASH["text"]["neutral"],
          SIZE_CLASSES[buttonSize],
          "cw-rounded-md cw-flex cw-items-center cw-no-underline",
        )}
        target={helpDefaultWindowName}
        to={helpCenterLink}
      >
        Learn more
      </Link>
      <Button size={buttonSize} variant="text" sentiment="neutral" onClick={onSelect}>
        {selectedOptionLabel}&nbsp;
        <Kbd className="cw-text-muted cw-leading-3">⏎</Kbd>
      </Button>
    </div>
  );
};
