import { Button, Size } from "@clockwise/design-system/src/components/Button";
import classNames from "classnames";
import React from "react";

import { Kbd, Select, SelectOption } from "@clockwise/design-system";
import { PersonAdd } from "@clockwise/icons";

type Props = {
  onClickAddPerson: () => void;
  onClickSubmit: () => void;
  onUpdateDuration: (duration: string) => void;
  size: "small" | "large";
  buttonSize: Size;
  durationValue: string | null;
};

const durationOptions = ["15", "30", "45", "60", "75", "90"];

export const ChatInputFooter = ({
  onClickAddPerson,
  onClickSubmit,
  size,
  buttonSize,
  onUpdateDuration,
  durationValue,
}: Props) => {
  return (
    <div
      className={classNames("cw-flex cw-justify-between cw-pb-2 cw-pl-2", {
        "cw-pr-2": size === "small",
        "cw-pr-4 cw-pt-2": size === "large",
      })}
    >
      <div className="cw-flex cw-items-center cw-gap-2 cw-ml-1 cw-text-subtle">
        {durationValue && (
          <Select
            size={"mini"}
            onChange={onUpdateDuration}
            value={durationValue}
            aria-label="Select duration in minutes"
          >
            {durationOptions.map((option) => (
              <SelectOption key={option} value={option}>
                {`${option} mins`}
              </SelectOption>
            ))}
            <SelectOption key={"Other"} value={"Other"}>
              Other
            </SelectOption>
          </Select>
        )}

        <Button
          variant={size === "small" ? "text" : "outlined"}
          sentiment="neutral"
          size={buttonSize}
          onClick={onClickAddPerson}
        >
          <PersonAdd className="cw-text-subtle cw-w-3.5 cw-h-3.5" />
        </Button>
      </div>
      <Button variant="solid" sentiment="positive" size={buttonSize} onClick={onClickSubmit}>
        Get proposal&nbsp;
        <Kbd size="xs" className="cw-leading-3">
          ⏎
        </Kbd>
      </Button>
    </div>
  );
};
