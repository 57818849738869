import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { getDateRange } from "@clockwise/web-commons/src/util/date.util";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";
import { MentionsSearchEventsQuery } from "../apollo/__generated__/MentionsSearchEvents.generated";
import { SearchEvent } from "../hooks/useEventsSearch";
// Based off of parseCalendarDataGQL
export const parseCalendarEventsForMentionsGQL = (
  calendarData: MentionsSearchEventsQuery,
  weeksToProcess: string[],
  zonedNow: DateTime,
  zone: string,
): SearchEvent[] => {
  const newEvents: SearchEvent[] = [];
  if (calendarData && getValue(calendarData.org)) {
    const calendarEvents = getValue(
      getValue(calendarData?.org, "Org")?.eventParentBaseWrapperByWeeksObject,
    )?.list;

    calendarEvents?.forEach?.((week) => {
      week.eventParentsByDay.forEach((byDay) => {
        byDay.eventParents?.forEach((eventParent) => {
          // Only process events that occur on weeksToProcess and are in the future
          eventParent.events.forEach((e) => {
            getDateRange(
              DateTime.fromISO(e.startTime.dateOrDateTime, {
                zone: getRenderTimeZone(),
              }).toISODate(),
              DateTime.fromISO(e.endTime.dateOrDateTime, { zone: getRenderTimeZone() })
                .minus({ seconds: 1 })
                .toISODate(),
            ).forEach((dateISO) => {
              if (
                !weeksToProcess
                  .flatMap((w) => getDateRange(w, DateTime.fromISO(w).plus({ days: 6 }).toISO()))
                  .includes(dateISO)
              ) {
                return;
              }
              if (
                !!e.title &&
                !!e.eventKey.externalEventId &&
                !!e.startTime.dateOrDateTime &&
                DateTime.fromISO(dateISO).setZone(zone).startOf("day") >= zonedNow.startOf("day") // if date is today or in the future
              ) {
                newEvents.push({
                  title: e.title,
                  externalEventId: e.eventKey.externalEventId,
                  startTime: e.startTime.dateOrDateTime,
                });
              }
            });
          });
        });
      });
    });
  }
  return newEvents;
};
