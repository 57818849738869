import { Tooltip } from "@clockwise/design-system";
import { MentionsV2 } from "@clockwise/schema";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useBoolean } from "usehooks-ts";
import {
  addCalendarIds,
  removeCalendarIds,
} from "../../../../state/actions/multi-calendar.actions";
import {
  useReadCalendarColors,
  useUpdateCalendarColors,
} from "../../../web-app-calendar/hooks/CalendarColorsContext";
import { SearchEvent } from "../../hooks/useEventsSearch";
import { putCaretAtEndOfInput } from "../../util/chatInput.util";
import { BetaLabel } from "./BetaLabel";
import { CancelProcessMessageButton } from "./CancelProcessMessageButton";
import "./ChatBox.css";
import { ChatInputFooter } from "./ChatInputFooter";
import { ChatInputHelp } from "./ChatInputHelp";
import { ChatInputLoading } from "./ChatInputLoading";
import { ChatInputModal } from "./ChatInputModal";
import { useKeyboardSelect } from "./ChatInputModal/hooks/useKeyboardSelect";
import { ChatInputTextArea } from "./ChatInputTextArea";
import { CommandBarActionSelect } from "./CommandBarActionSelect";
import { CommandBarActionSelectFooter } from "./CommandBarActionSelectFooter";
import { useChatActionChip } from "./hooks/useChatActionChip";
import { useColorCoding } from "./hooks/useColorCoding";
import { Mentions, useMentionChips } from "./hooks/useMentionChips";
import { usePlaceholderTips } from "./hooks/usePlaceholderTips";
import { CalendarIdToNamesMap, useSelectedCalendarNames } from "./hooks/useSelectedCalendarNames";
import { useSuggestedInputText } from "./hooks/useSuggestedInputText";
import { deferredCallback } from "./utils/deferredCallback";
import { generateChatActionCQL } from "./utils/generateChatActionCQL";
import { hasCustomText } from "./utils/hasCustomText";
import { insertTextToDOM } from "./utils/insertTextToDOM";
import { nodeListToChipArray } from "./utils/nodeListToChipArray";
type OnSubmit = (
  innerText: string,
  mentions: MentionsV2,
  cql?: string | null | undefined,
) => Promise<void>;

export type Props = {
  disabled?: boolean;
  disabledReason?: string;
  focusInput?: boolean;
  hasActiveProposal: boolean;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: OnSubmit;
  onFocusCallback?: () => void;
  commandBarEnabled?: boolean;
  size?: "small" | "large";
};

export const ChatInput = ({
  disabled = false,
  disabledReason,
  focusInput = false,
  hasActiveProposal,
  loading = false,
  onCancel,
  onSubmit,
  onFocusCallback,
  commandBarEnabled,
  size = "small",
}: Props) => {
  const dispatch = useDispatch();
  const dispatchCalendarColors = useUpdateCalendarColors();

  const inputRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { onChange: updateChipColors } = useColorCoding({ inputRef });
  const [durationValue, setDurationValue] = useState<string | null>(null);

  const calendarColorMap = useReadCalendarColors();
  useEffect(() => {
    updateChipColors(calendarColorMap);
  }, [calendarColorMap, updateChipColors]);

  const onSelectAction = () => {
    deferredCallback(() => {
      // select for new mention after action is selected
      evalInputMentions(inputRef.current);

      putCaretAtEndOfInput(inputRef.current);
    });
  };

  const { calendarIdToNamesMap } = useSelectedCalendarNames();

  const buttonSize = size === "small" ? "mini" : "xsmall";
  const chipSize = size === "small" ? "mini" : "large";

  const {
    chatAction,
    chatActionOptions,
    onCancelChatAction,
    onSelectChatAction,
    evalInputAction,
    onActionRemoved,
    searchSymbol: actionSearchSymbol,
  } = useChatActionChip({
    ref: inputRef,
    onSelect: onSelectAction,
    commandBarEnabled,
    size: chipSize,
  });

  useEffect(() => {
    if (chatAction?.type === "schedule" && commandBarEnabled) {
      setDurationValue("30");
    } else {
      setDurationValue(null);
    }
  }, [chatAction]);

  const onSelectMention = (mentions: Mentions) => {
    deferredCallback(() => putCaretAtEndOfInput(inputRef.current));
    const { personMentions } = mentions;
    personMentions.forEach((newPerson) => {
      dispatch(addCalendarIds([newPerson.calendarId]));
      dispatchCalendarColors({ type: "add-calendar", payload: newPerson.calendarId });
    });
  };

  const onUnselectMention = (mentions: Mentions) => {
    const { personMentions } = mentions;
    personMentions.forEach((newPerson) => {
      dispatch(removeCalendarIds([newPerson.calendarId]));
      dispatchCalendarColors({ type: "remove-calendar", payload: newPerson.calendarId });
    });
  };

  const {
    eventMentionOptions,
    mentions,
    onCancelMentions,
    onMentionRemoved,
    onSelectEvent,
    onSelectPerson,
    evalInputMentions,
    personMentionOptions,
    searchSymbol: mentionSearchSymbol,
    onAddMultiCalIdToMentions,
  } = useMentionChips({
    ref: inputRef,
    onSelect: onSelectMention,
    onUnselect: onUnselectMention,
    size: chipSize,
  });

  const { value: isSubmittable, setValue: setIsSubmittable } = useBoolean(false);

  const onSuggestedInputText = useCallback(() => {
    onCancelMentions();
    onCancelChatAction();

    deferredCallback(() => {
      evalInputAction(inputRef.current, true);

      evalInputMentions(inputRef.current);

      const hasText = !isEmpty(inputRef.current?.innerText.trim());
      setIsSubmittable(hasText);
      putCaretAtEndOfInput(inputRef.current);
    });
  }, [onCancelChatAction, onCancelMentions, evalInputAction, evalInputMentions, setIsSubmittable]);

  useSuggestedInputText({
    disabled,
    ref: inputRef,
    onChange: onSuggestedInputText,
    keyboardShortcutsEnabled: commandBarEnabled,
  });

  const {
    value: isInputFocused,
    setTrue: setInputFocused,
    setFalse: setInputNotFocused,
  } = useBoolean(true);

  const focusCallback = () => {
    setInputFocused();
    onFocusCallback?.();
  };

  const blurCallback = () => {
    setInputNotFocused();
  };

  let placeholderText = usePlaceholderTips({
    hasActiveProposal,
    rotateText: isInputFocused && !isSubmittable,
  });
  if (commandBarEnabled) {
    placeholderText = "Type a command";
  }

  const isModalOpen =
    !isEmpty(chatActionOptions) || !isEmpty(personMentionOptions) || !isEmpty(eventMentionOptions);

  const cancelMessage = () => {
    onCancel();
  };

  const clearMessage = () => {
    if (inputRef.current) {
      inputRef.current.innerText = "";
      setIsSubmittable(false);
    }
    onCancelChatAction();
    onCancelMentions();

    deferredCallback(() => putCaretAtEndOfInput(inputRef.current));
  };
  const submitMessage = async () => {
    const input = inputRef.current;

    if (!input || !isSubmittable) {
      return;
    }

    let text = input.innerText.trim();

    // If command bar duration was set, append it to the action label
    if (chatAction && chatAction.type === "schedule" && commandBarEnabled && durationValue) {
      const durationText = durationValue.toString() + " minutes";
      const actionLabel = chatAction.label;
      text = text.replace(actionLabel, actionLabel + " " + durationText);
    }

    if (chatAction && chatAction.autoAppendText && text.endsWith(chatAction.appendText)) {
      text = text.slice(0, -chatAction.appendText.length).trimEnd();
    }

    const canGenerateCQL = chatAction && !hasCustomText(input);

    // If duration is "Other", we don't want to pass a duration to the CQL
    const duration =
      durationValue && durationValue !== "Other" ? parseInt(durationValue) : undefined;

    const cql = canGenerateCQL
      ? generateChatActionCQL({
          action: chatAction.type,
          calendarIds: mentions.personMentions.map((person) => person.calendarId),
          externalEventId: mentions.eventMentions[0]?.externalEventId,
          duration,
        })
      : null;

    void onSubmit(text, mentions, cql);

    // Clear the input
    clearMessage();
  };

  const cleanupUnwantedAppendText = useCallback(
    (input: HTMLDivElement | null) => {
      if (input) {
        if (chatAction && chatAction.autoAppendText) {
          input.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              deferredCallback(() => {
                const nbsp = String.fromCharCode(160);
                const spaceAppendTextNbsp = `${nbsp}${chatAction.appendText}${nbsp}`;
                const textNode = node as Text;
                if (textNode.textContent?.startsWith(spaceAppendTextNbsp)) {
                  textNode.textContent = textNode.textContent.slice(
                    chatAction.appendText.length + 1,
                  );
                  putCaretAtEndOfInput(input);
                }
              });
            }
          });
        }
      }
    },
    [chatAction],
  );

  const handleObservation = useCallback(
    (mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          const addedChips = nodeListToChipArray(mutation.addedNodes);
          const removedChips = nodeListToChipArray(mutation.removedNodes);

          // Chips may be "replaced" and appear in both lists
          // we need to find the chips removed without replacement
          const netRemovedChips = removedChips.filter(
            (chip) =>
              !addedChips.some(
                (addedChip) =>
                  addedChip.dataset.chip_type === chip.dataset.chip_type &&
                  addedChip.dataset.chip_id === chip.dataset.chip_id,
              ),
          );

          netRemovedChips.forEach((chip) => {
            if (chip.dataset.chip_type === "action") {
              onActionRemoved({ element: chip });
            } else if (chip.dataset.chip_type === "mention") {
              onMentionRemoved({ element: chip });
            }
          });
        }
      }

      cleanupUnwantedAppendText(inputRef.current);
      evalInputAction(inputRef.current, false);
      evalInputMentions(inputRef.current);

      const hasText = !isEmpty(inputRef.current?.innerText.trim());
      setIsSubmittable(hasText);
    },
    [
      cleanupUnwantedAppendText,
      evalInputAction,
      evalInputMentions,
      setIsSubmittable,
      onActionRemoved,
      onMentionRemoved,
    ],
  );

  useEffect(() => {
    const observer = new MutationObserver(handleObservation);

    if (inputRef.current) {
      observer.observe(inputRef.current, {
        characterData: true,
        childList: true,
        subtree: true,
        characterDataOldValue: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [inputRef, handleObservation]);

  const actionAutoAppendText = useCallback(() => {
    deferredCallback(() => {
      if (chatAction?.autoAppendText) {
        inputRef.current?.append(chatAction.appendText);

        evalInputMentions(inputRef.current);
        putCaretAtEndOfInput(inputRef.current);
      }
    });
  }, [chatAction, evalInputMentions]);

  const handleSelectPerson = (
    person: { primaryCalendar: string; profile: { givenName: string } },
    fromMouseEvent: boolean,
  ) => {
    onSelectPerson(person);
    if (fromMouseEvent) {
      actionAutoAppendText();
    }
  };

  const handleAddMultiCalIdToMentions = useCallback(
    (calendarIdMap: CalendarIdToNamesMap) => {
      const calendarIds = Object.keys(calendarIdMap);
      calendarIds.forEach((calendarId) => {
        onAddMultiCalIdToMentions({
          calendarId,
          name: calendarIdMap[calendarId].displayName,
        });
      });
    },
    [onAddMultiCalIdToMentions],
  );

  const handleSelectEvent = (event: SearchEvent, fromMouseEvent: boolean) => {
    onSelectEvent(event);
    if (fromMouseEvent) {
      actionAutoAppendText();
    }
  };

  const commandBarChatActionsVisible = !!commandBarEnabled && !isEmpty(chatActionOptions);

  const handleCommandBarActionSelect = useCallback(
    (index: number) => {
      const action = chatActionOptions[index];
      onSelectChatAction(action);
      if (action.includeMultiCalSelection) {
        handleAddMultiCalIdToMentions(calendarIdToNamesMap);
      }
    },
    [onSelectChatAction, chatActionOptions, handleAddMultiCalIdToMentions, calendarIdToNamesMap],
  );

  const {
    activeIndex: commandBarActionSelectActiveIndex,
    setActiveIndex: setCommandBarActionSelectActiveIndex,
  } = useKeyboardSelect({
    active: commandBarChatActionsVisible,
    inputRef,
    onSelect: handleCommandBarActionSelect,
    optionCount: chatActionOptions.length,
    preventWrapAround: true,
  });

  const onUpdateDuration = (duration: string) => {
    setDurationValue(duration);
  };

  return (
    <>
      <ChatInputModal
        chatActionOptions={commandBarEnabled ? [] : chatActionOptions}
        containerRef={containerRef}
        eventMentionOptions={eventMentionOptions}
        inputRef={inputRef}
        isOpen={!disabled && isInputFocused && isModalOpen}
        onSelectChatAction={onSelectChatAction}
        onSelectEvent={handleSelectEvent}
        onSelectPerson={handleSelectPerson}
        personMentionOptions={personMentionOptions}
        searchSymbol={actionSearchSymbol || mentionSearchSymbol}
      />
      <Tooltip title={disabled && disabledReason ? disabledReason : ""}>
        <div className="cw-group/ChatInput" ref={containerRef}>
          <div
            className={classNames(
              "cw-transition ease-in-out",
              "cw-outline-transparent group-focus-within/ChatInput:cw-border-positive-interactable",
              isInputFocused
                ? "group-hover/ChatInput:cw-shadow-sm"
                : "group-hover/ChatInput:cw-shadow-md",
              "cw-rounded-xl",
              "cw-overflow-hidden",
              "cw-flex cw-flex-col",
              "cw-body-base",
              "cw-bg-default",
              {
                "cw-shadow-md": commandBarEnabled,
                "cw-shadow-sm group-focus-within/ChatInput:cw-shadow-none": !commandBarEnabled,
                "cw-border-default cw-outline cw-border cw-border-solid":
                  !loading && !commandBarEnabled,
                "cw-prism-outline cw-prism-outline-loading ": loading,
                "cw-prism-outline cw-prism-background-subtle": commandBarEnabled,
              },
            )}
          >
            <div className="cw-prism-mask cw-rounded-xl" />
            <div
              className={classNames(
                "cw-flex cw-flex-row cw-space-x-1 cw-justify-start cw-items-center",
                { "cw-border-b cw-border-solid cw-border-neutral": commandBarEnabled },
              )}
            >
              {!loading ? (
                <>
                  <ChatInputTextArea
                    autoFocus={focusInput}
                    disabled={disabled}
                    inputRef={inputRef}
                    onBlur={blurCallback}
                    onFocus={focusCallback}
                    onSubmit={submitMessage}
                    placeholder={placeholderText}
                    submitOnEnter={!isModalOpen}
                    size={size}
                  />
                  {!isSubmittable && !commandBarEnabled && <BetaLabel />}
                </>
              ) : (
                <>
                  <div className="cw-grow cw-py-2.5 cw-pl-3">
                    <ChatInputLoading />
                  </div>
                  <div className="cw-pr-1.5">
                    <CancelProcessMessageButton onCancel={cancelMessage} />
                  </div>
                </>
              )}
            </div>
            {commandBarEnabled && (
              <CommandBarActionSelect
                options={chatActionOptions}
                activeIndex={commandBarActionSelectActiveIndex}
                setActiveIndex={setCommandBarActionSelectActiveIndex}
                onSelect={handleCommandBarActionSelect}
                multiCalNames={calendarIdToNamesMap}
              />
            )}
            {commandBarChatActionsVisible ? (
              <CommandBarActionSelectFooter
                buttonSize={buttonSize}
                onSelect={() => {
                  handleCommandBarActionSelect(commandBarActionSelectActiveIndex);
                }}
                selectedOptionLabel={chatActionOptions[commandBarActionSelectActiveIndex]?.label}
              />
            ) : isSubmittable && !loading ? (
              <ChatInputFooter
                onClickAddPerson={() => {
                  inputRef.current?.focus();
                  insertTextToDOM("@");
                  evalInputMentions(inputRef.current);
                }}
                onClickSubmit={submitMessage}
                size={size}
                buttonSize={buttonSize}
                durationValue={durationValue}
                onUpdateDuration={onUpdateDuration}
              />
            ) : (
              <ChatInputHelp />
            )}
          </div>
        </div>
      </Tooltip>
    </>
  );
};
