import { TextResponseTypeEnum } from "@clockwise/schema";
import { uniqueId } from "lodash";
import { AssistantMessageDetailsFragment } from "../apollo/__generated__/AssistantMessageDetails.fragment.generated";
import { GQLAssistantMessageTextResponse } from "./types";

const TextRepsonse: GQLAssistantMessageTextResponse = {
  __typename: "TextResponse",
  id: `${Date.now()}-${uniqueId()}`,
  conversationId: `${Date.now()}-${uniqueId()}`,
  text: "",
  type: TextResponseTypeEnum.BASIC_TEXT,
};

export const tempAssistantMessage: AssistantMessageDetailsFragment = {
  ...TextRepsonse,
};

export const tempCancelAssistantMessage: AssistantMessageDetailsFragment = {
  ...TextRepsonse,
  text: "No problem! We can try again later.",
};

export const tempConfirmProposalMessage: AssistantMessageDetailsFragment = {
  ...TextRepsonse,
  text: "Confirming...",
};
