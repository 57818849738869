import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { ChatAction } from "../hooks/useChatActionChip";
import { useKeyboardSelect } from "./hooks/useKeyboardSelect";

type Props = {
  inputRef: React.RefObject<HTMLDivElement>;
  onSelect: (action: ChatAction | null) => void;
  options: ChatAction[];
};

export const ActionSelect = ({ inputRef, onSelect, options }: Props) => {
  const handleSelect = useCallback(
    (index: number) => {
      const action = options[index];
      onSelect(action);
    },
    [onSelect, options],
  );

  const isActive = !isEmpty(options);

  const { activeIndex, setActiveIndex } = useKeyboardSelect({
    active: isActive,
    inputRef,
    onSelect: handleSelect,
    optionCount: options.length,
  });

  useEffect(() => {
    // Reset index to top on search change
    setActiveIndex(0);
  }, [options, setActiveIndex]);

  return (
    <>
      {options.map((option, index) => (
        <ActionItem
          highlight={index === activeIndex}
          icon={option.iconOption}
          key={index}
          onMouseDown={() => handleSelect(index)}
          onMouseEnter={() => setActiveIndex(index)}
          label={option.label}
        />
      ))}
    </>
  );
};

type ActionItemProps = {
  highlight: boolean;
  icon?: React.JSX.Element;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  label: string;
};

const ActionItem = ({ highlight, icon, onMouseDown, onMouseEnter, label }: ActionItemProps) => {
  return (
    <div
      tabIndex={0}
      onMouseEnter={onMouseEnter}
      className={classNames(
        "cw-flex cw-items-center cw-space-x-1",
        "cw-py-1 cw-px-2 cw-mb-1",
        "cw-cursor-pointer",
        "cw-rounded",
        { "cw-bg-default-hover": highlight },
      )}
      onMouseDown={onMouseDown}
    >
      {icon}
      <span className="cw-text-neutral-muted cw-body-sm cw-font-medium">{label}</span>
    </div>
  );
};
