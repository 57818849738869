import { useEffect, useState } from "react";

export const INTERVAL = 4 * 1000;

export const PLACEHOLDER_TEXTS = [
  "Schedule or reschedule...",
  "Schedule or reschedule...",
  "Type @ to add attendees...",
  "Type # to search events...",
  "Type / for commands...",
] as const;

export const IN_PROGRESS_PLACEHOLDER_TEXT = "Refine request...";

export const usePlaceholderTips = ({
  rotateText,
  hasActiveProposal,
}: {
  rotateText: boolean;
  hasActiveProposal: boolean;
}) => {
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (rotateText) {
      interval = setInterval(() => {
        setPlaceholderIndex((prevIndex) => (prevIndex + 1) % PLACEHOLDER_TEXTS.length);
      }, 4 * 1000);
    } else {
      if (interval) {
        setPlaceholderIndex(0);
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        setPlaceholderIndex(0);
        clearInterval(interval);
      }
    };
  }, [rotateText]);

  if (hasActiveProposal) {
    return IN_PROGRESS_PLACEHOLDER_TEXT;
  }

  return PLACEHOLDER_TEXTS[placeholderIndex];
};
