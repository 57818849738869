import {
  INPUT_BACKGROUND_CLASS,
  INPUT_BORDER_CLASS,
  INPUT_BORDER_SHADOW_CLASS,
  INPUT_PADDING_CLASS,
  INPUT_TEXT_CLASS,
} from "@clockwise/design-system/src/constants/classes";
import { Size, Variant as _Variant } from "@clockwise/design-system/src/constants/types";
import { border_muted } from "@clockwise/design-system/tokens";
import { ExpandMore } from "@clockwise/icons";
import classNames from "classnames";
import { noop } from "lodash";
import React, { forwardRef } from "react";
import { createRoot } from "react-dom/client";

type Variant = Extract<_Variant, "default" | "inline" | "elevated">;

export type ChipType = "action" | "mention" | "select";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  chipType?: ChipType;
  colorId?: string;
  icon?: JSX.Element;
  id?: string;
  placeholder?: string;
  size?: Size;
  text?: string;
  variant?: Variant;
  onClick?: () => void;
}

// eslint-disable-next-line react/display-name
export const ChatInputChip = forwardRef<HTMLElement, Props>(
  (
    {
      chipType = "mention",
      colorId = "default",
      icon,
      id = "",
      size = "medium",
      text,
      variant = "default",
      onClick,
    },
    ref,
  ) => {
    return (
      <span
        ref={ref}
        aria-label={text}
        aria-roledescription={`${chipType} chip`}
        cw-id="chatInputChip"
        data-chip_type={chipType}
        data-chip_id={id}
        data-color_id={colorId}
        contentEditable={false}
        className={classNames(
          "cw-group/Chip",
          "cw-font-body",
          "cw-whitespace-nowrap",
          "cw-rounded-md",
          INPUT_TEXT_CLASS[size],
          INPUT_BORDER_CLASS,
          INPUT_BORDER_SHADOW_CLASS[variant],
          INPUT_BACKGROUND_CLASS[variant]["neutral"],
          INPUT_PADDING_CLASS[variant][size],
          "cw-font-medium",
          {
            "cw-cursor-pointer": chipType === "select",
          },
        )}
        style={{
          padding: 3,
          borderColor: border_muted,
        }}
        onClick={onClick ?? noop}
      >
        <span>
          <span style={{ position: "relative", top: 3, right: 2 }}>{icon}</span>
          <span className="label">{text}</span>
          {chipType === "select" && (
            <span>
              <ExpandMore
                className={classNames(
                  "cw-relative cw-top-[2px] cw-ml-[2px]",
                  INPUT_TEXT_CLASS[size],
                )}
              />
            </span>
          )}
        </span>
      </span>
    );
  },
);

export const chipAsNode = (props: Props, callback: (node: ChildNode | null) => void) =>
  toNode(<ChatInputChip {...props} ref={callback} />);

const toNode = (element: JSX.Element) => {
  const div = document.createElement("div");
  const root = createRoot(div);
  root.render(element);
};
