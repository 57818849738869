import { motion } from "framer-motion";
import React from "react";
import { useBoolean } from "usehooks-ts";

const helpTexts = [
  "Nothing will change until explicitly confirmed",
  'Use "@" to mention people',
  'Use "#" to mention events',
  'Start a prompt with "/" to see commands',
];

export const ChatInputHelp = () => {
  const [helpTextIndex, setHelpTextIndex] = React.useState(0);
  const { value: hasClicked, setTrue: setHasClicked } = useBoolean(false);

  const handleClick = () => {
    setHasClicked();
    setHelpTextIndex((prev) => (prev + 1) % helpTexts.length);
  };

  const text = helpTexts[helpTextIndex];

  return (
    <div
      className="cw-bg-neutral cw-px-3 cw-py-2 cw-body-sm cw-font-medium cw-mx-px cw-mb-px cw-rounded-b-xl cw-text-center cw-text-subtle cw-text-[10px] cw-cursor-pointer cw-select-none"
      onClick={handleClick}
    >
      <motion.div
        key={text}
        style={{ position: "relative" }}
        initial={hasClicked ? { opacity: 0.5, top: 10 } : { opacity: 1, top: 0 }}
        animate={{ opacity: 1, top: 0 }}
      >
        {text}
      </motion.div>
    </div>
  );
};
