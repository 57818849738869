import { logger } from "#webapp/src/util/logger.util";
import { AvailabilityStatus } from "@clockwise/schema/v2";
import {
  useGatewayMutation,
  useGatewayQuery,
} from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { noop } from "lodash";
import { DateTime } from "luxon";
import React, { createContext, useCallback, useEffect, useMemo } from "react";
import {
  CollaboratorsDocument,
  UpdateCustomCollaboratorsDocument,
} from "../../apollo/__generated__/Collaborators.v2.generated";
import { useSetAIError } from "./AIErrorContext";

export type Collaborator = {
  calendarId: string;
  sharedMinutes: number | null;
  isPinned: boolean;
  fullName: string | null;
  externalImageUrl: string | null;
  availabilityStatus: AvailabilityStatus | null;
  timeZone: string | null;
};

type CollaboratorsContextValue = {
  topCollaborators: Collaborator[];
  customCollaborators: Collaborator[];
  loading: boolean;
  updateCollaborators: (collaborators: Collaborator[]) => void;
};

const emptyCollaboratorsValue = {
  topCollaborators: [],
  customCollaborators: [],
  loading: false,
  updateCollaborators: noop,
};

const CollaboratorsContext = createContext<CollaboratorsContextValue>(emptyCollaboratorsValue);

export const useCollaborators = () => {
  const context = React.useContext(CollaboratorsContext);
  if (!context) {
    throw new Error("useCollaborators must be used within a CollaboratorsContext");
  }
  return context;
};

export const CollaboratorsProvider = ({ children }: { children: React.ReactNode }) => {
  const setError = useSetAIError();
  const timeZone = getRenderTimeZone();
  const today = DateTime.now().setZone(timeZone);

  const [updateCustomCollaborators] = useGatewayMutation(UpdateCustomCollaboratorsDocument);

  const { data: colData, loading } = useGatewayQuery(CollaboratorsDocument, {
    variables: {
      start: today.minus({ weeks: 3 }).startOf("week").toISODate(),
      end: today.endOf("week").toISODate(),
      timeZone: timeZone ?? "America/Los_Angeles",
    },
    onError: (error: Error) => {
      setError({
        error: error,
        showUserMessage: false,
        message: "Failed to fetch collaborators",
      });
    },
  });

  // SAM: TEMPORARY CHECK TO CONFIRM IF USER'S TIMEZONE IS BEING NOT SET
  // https://clockwisehq.slack.com/archives/C9HBN4CDC/p1737565947965649
  useEffect(() => {
    if (!loading && !timeZone) {
      logger.warn(`Collaborators: Timezone not set for this user`);
    }
  }, [loading, timeZone]);

  const updateCollaborators = useCallback(
    async (collaborators: Collaborator[]) => {
      await updateCustomCollaborators({
        variables: {
          calendarIds: collaborators.map((collab) => collab.calendarId),
          timeZone: timeZone ?? "America/Los_Angeles",
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateCustomCollaborators: {
            __typename: "User",
            id: colData?.currentUser?.id || "",
            customCollaborators: collaborators.map((collab) => {
              return {
                __typename: "Collaborator",
                id: collab.calendarId,
                fullName: collab.fullName,
                externalImageUrl: collab.externalImageUrl,
                usersTimeZone: collab.timeZone,
                availabilityStatus: collab.availabilityStatus,
              };
            }),
          },
        },
      });
    },
    [colData?.currentUser?.id, updateCustomCollaborators],
  );

  const customCollaborators = useMemo(
    () =>
      colData?.currentUser?.customCollaborators?.map((customCollaborator) => {
        const topCollaboratorMatch = colData?.currentUser?.topCollaborators?.find((topCollab) => {
          return topCollab.id === customCollaborator.id;
        });

        return {
          calendarId: customCollaborator.id,
          sharedMinutes: topCollaboratorMatch?.sharedMinutes ?? 0,
          isPinned: true,
          fullName: customCollaborator.fullName,
          externalImageUrl: customCollaborator.externalImageUrl,
          availabilityStatus: customCollaborator.availabilityStatus,
          timeZone: customCollaborator.usersTimeZone,
        };
      }) ?? [],
    [colData?.currentUser],
  );

  const topCollaborators = useMemo(
    () =>
      colData?.currentUser?.topCollaborators?.map((topCollaborator) => ({
        calendarId: topCollaborator.id,
        sharedMinutes: topCollaborator.sharedMinutes,
        isPinned: false,
        fullName: topCollaborator.fullName,
        externalImageUrl: topCollaborator.externalImageUrl,
        availabilityStatus: null,
        timeZone: null,
      })) ?? [],
    [colData?.currentUser],
  );

  const value = useMemo(
    () => ({ topCollaborators, customCollaborators, loading, updateCollaborators }),
    [topCollaborators, customCollaborators, updateCollaborators, loading],
  );

  return <CollaboratorsContext.Provider value={value}>{children}</CollaboratorsContext.Provider>;
};
