import classNames from "classnames";
import * as React from "react";

// styles
import { Button, Sentiment } from "@clockwise/design-system";

export interface CalloutProps {
  children?: React.HTMLAttributes<HTMLDivElement>["children"];
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
  className?: string;
  level?: "info" | "warning" | "error";
}

export interface CalloutButtonProps extends CalloutProps {
  buttonProps?: React.ComponentProps<typeof Button>;
}

const LEVEL_TO_SENTIMENT: { [k in "info" | "warning" | "error"]: Sentiment } = {
  info: "info",
  warning: "warning",
  error: "destructive",
};

export const Callout = ({ children, style, className, level = "info" }: CalloutProps) => {
  let colorClass;
  switch (level) {
    case "error":
      colorClass = "cw-border-l-destructive";
      break;
    case "warning":
      colorClass = "cw-border-l-warning";
      break;
    case "info":
    default:
      colorClass = "cw-border-l-info";
      break;
  }

  return (
    <div
      style={style}
      className={classNames(
        "cw-flex cw-items-center cw-p-2 cw-border cw-border-l-[3px] cw-border-solid cw-border-default",
        colorClass,
        className,
      )}
    >
      {(typeof children === "string" && <div className="cw-body-base">{children}</div>) || children}
    </div>
  );
};

export const CalloutButton = ({
  children,
  buttonProps,
  level,
  ...calloutProps
}: CalloutButtonProps) => {
  return (
    <Callout {...calloutProps} level={level}>
      {(typeof children === "string" && <div className="cw-body-base cw-flex-1">{children}</div>) ||
        children}
      <Button
        {...buttonProps}
        sentiment={level ? LEVEL_TO_SENTIMENT[level] : "neutral"}
        variant="outlined"
      />
    </Callout>
  );
};
