import { ApolloQueryResult, useQuery } from "@apollo/client";
import { useWindowFocus } from "@clockwise/web-commons/src/util/react.util";
import { noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SchedulingProposalStateEnum } from "@clockwise/schema";
import { Exact } from "@clockwise/schema/v2";
import { useLocalStorage } from "usehooks-ts";
import {
  ChatHistoryQueryDocument,
  ChatHistoryQueryQuery,
} from "../apollo/__generated__/ChatHistory.generated";
import { GQLAIMessage } from "../utils/types";
import { useSetAIError } from "./AIErrorContext";

type DebugInfoMap = Record<string, string>;

export type DebugModel = "default";

export type AIMessageContextValue = {
  chatHistoryId: string | null;
  messages: GQLAIMessage[];
  loadingHistory: boolean;
  shouldExcludeAi: boolean;
  processingMessage: boolean;
  setProcessingMessage: (processing: boolean) => void;
  addDebugInfo: (msgId: string, debugInfo: string) => void;
  debugInfoMap: DebugInfoMap;
  debugModel: DebugModel;
  setDebugModel: (model: string) => void;
  pollAndRefetchOnFocus: boolean;
  setPollAndRefetchOnFocus: (pollAndRefetchOnFocus: boolean) => void;
  containsActiveProposal: boolean;
  cancelProcessing: boolean;
  setCancelProcessing: (cancel: boolean) => void;
  reprocessingMessage: boolean;
  setReprocessingMessage: (reprocessing: boolean) => void;
  confirmingMessage: boolean;
  setConfirmingMessage: (confirming: boolean) => void;
  refetchChatHistory: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<ChatHistoryQueryQuery>>;
};
export const emptyChatValue: AIMessageContextValue = {
  chatHistoryId: null,
  messages: [],
  loadingHistory: false,
  shouldExcludeAi: false,
  processingMessage: false,
  setProcessingMessage: noop,
  addDebugInfo: noop,
  debugInfoMap: {},
  debugModel: "default",
  setDebugModel: noop,
  pollAndRefetchOnFocus: true,
  setPollAndRefetchOnFocus: noop,
  containsActiveProposal: false,
  cancelProcessing: false,
  setCancelProcessing: noop,
  reprocessingMessage: false,
  setReprocessingMessage: noop,
  confirmingMessage: false,
  setConfirmingMessage: noop,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  refetchChatHistory: noop,
};

/* This is the default for polling, which means the query does not poll. */
const DO_NOT_POLL = 0;
const DEFAULT_POLL_INTERVAL = 30 * 60 * 1000;
// We are setting `PROCESSING_POLL_INTERVAL` to 1 second because messages sent from the extension gCal button
// do not return with a response, nor do refreshed tabs during processing message,
// it requires polling to get the response. Since our longest processing time is 30 seconds,
// and most processing times is < 5 seconds, we should aggressively poll and can afford to do so.
const PROCESSING_POLL_INTERVAL = 1 * 1000;
const LOADING_SUGGESTIONS_POLL_INTERVAL = 1 * 1000;
const USE_GPT_DEBUG_MODEL = "UseGPTDebugModel";
const POLL_AND_REFETCH_ON_FOCUS = "AIChat:PollAndRefetchOnFocus";

export const useAIMessageContext = () => {
  const context = React.useContext(AIMessageContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatContext");
  }
  return context;
};

export const AIMessageContext = React.createContext<AIMessageContextValue>(emptyChatValue);

export const AIMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const setError = useSetAIError();
  const [processingMessage, setProcessingMessage] = useState(false);
  const [externalProcessingMessage, setExternalProcessingMessage] = useState(false);
  const [reprocessingMessage, setReprocessingMessage] = useState(false);
  const [confirmingMessage, setConfirmingMessage] = useState(false);
  const [debugInfoMap, setDebugInfoMap] = useState<DebugInfoMap>({});
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const [pollInterval, setPollInterval] = useState(DO_NOT_POLL);

  const [debugModel, setDebugModel] = useLocalStorage<DebugModel>(USE_GPT_DEBUG_MODEL, "default");
  const [pollAndRefetchOnFocus, setPollAndRefetchOnFocus] = useLocalStorage<boolean>(
    POLL_AND_REFETCH_ON_FOCUS,
    true,
  );

  const { data, loading, refetch } = useQuery(ChatHistoryQueryDocument, {
    onError: (error) => {
      // filter network errors as these are not actionable
      if (error.message !== "Failed to fetch") {
        setError({ error: error, message: "Failed to fetch ChatHistory", showUserMessage: false });
      }
    },
    pollInterval,
  });
  const isWindowFocused = useWindowFocus();
  const isProcessing = data?.viewer?.user?.chatHistory?.isProcessing ?? false;
  const shouldExcludeAi = data?.viewer?.user?.chatHistory?.shouldExcludeAi ?? false;
  const waitingOnSuggestions = !!data?.viewer.user?.chatHistory?.messages.some(
    (message) =>
      message.__typename === "ProposalResponse" &&
      message.proposal.state === SchedulingProposalStateEnum.LOADING_SUGGESTIONS,
  );

  useEffect(() => {
    if (!isWindowFocused) {
      setPollInterval(DO_NOT_POLL);
      return;
    }

    // If the BE is still processing a user message,
    // such as on refresh, we want to poll more frequently
    const pollingInterval = waitingOnSuggestions
      ? LOADING_SUGGESTIONS_POLL_INTERVAL
      : isProcessing
      ? PROCESSING_POLL_INTERVAL
      : DEFAULT_POLL_INTERVAL;

    void refetch();
    setPollInterval(pollingInterval);

    setProcessingMessage(isProcessing);
  }, [isProcessing, isWindowFocused, pollAndRefetchOnFocus, refetch, waitingOnSuggestions]);

  const containsActiveProposal = !!data?.viewer?.user?.chatHistory?.messages.some((message) => {
    return (
      message.__typename === "ProposalResponse" &&
      (message?.proposal.state === SchedulingProposalStateEnum.ACTIVE ||
        message?.proposal.state === SchedulingProposalStateEnum.INCOMPLETE)
    );
  });

  const addDebugInfo = useCallback(
    (id: string, debugInfo: string) => {
      setDebugInfoMap({ ...debugInfoMap, [id]: debugInfo });
    },
    [debugInfoMap],
  );

  const value = useMemo(
    () => ({
      chatHistoryId: data?.viewer?.user?.chatHistory?.id ?? null,
      messages: data?.viewer?.user?.chatHistory?.messages ?? [],
      processingMessage: processingMessage || externalProcessingMessage,
      setProcessingMessage: setExternalProcessingMessage,
      containsActiveProposal: Boolean(containsActiveProposal),
      loadingHistory: loading,
      shouldExcludeAi,
      addDebugInfo: addDebugInfo,
      debugInfoMap: debugInfoMap,
      debugModel: debugModel,
      setDebugModel: (model: DebugModel) => setDebugModel(model),
      pollAndRefetchOnFocus,
      setPollAndRefetchOnFocus,
      cancelProcessing,
      setCancelProcessing,
      reprocessingMessage,
      setReprocessingMessage,
      confirmingMessage,
      setConfirmingMessage,
      refetchChatHistory: refetch,
    }),
    [
      data?.viewer?.user?.chatHistory?.id,
      data?.viewer?.user?.chatHistory?.messages,
      processingMessage,
      externalProcessingMessage,
      containsActiveProposal,
      loading,
      shouldExcludeAi,
      addDebugInfo,
      debugInfoMap,
      debugModel,
      pollAndRefetchOnFocus,
      setPollAndRefetchOnFocus,
      cancelProcessing,
      reprocessingMessage,
      confirmingMessage,
      refetch,
      setDebugModel,
    ],
  );

  return <AIMessageContext.Provider value={value}>{children}</AIMessageContext.Provider>;
};
