import { EventCardAttendee } from "#webapp/src/components/event-card/types.js";
import { AttendeeAvatar } from "@clockwise/web-commons/src/ui/AttendeeAvatar";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { MouseEventHandler, useCallback, useEffect } from "react";
import { useKeyboardSelect } from "./hooks/useKeyboardSelect";

type Props = {
  inputRef: React.RefObject<HTMLDivElement>;
  onSelect: (
    person: { primaryCalendar: string; profile: { givenName: string | null } | null } | null,
    fromMouseEvent: boolean,
  ) => void;
  options: EventCardAttendee[];
};

export const PersonSelect = ({ inputRef, onSelect, options }: Props) => {
  const handleSelect = useCallback(
    (index: number, fromMouseEvent: boolean) => {
      onSelect(options[index], fromMouseEvent);
    },
    [onSelect, options],
  );

  const isActive = !isEmpty(options);

  const onKeyboardSelect = useCallback((index: number) => handleSelect(index, false), [
    handleSelect,
  ]);

  const onMouseSelect = useCallback(
    (index: number) => {
      handleSelect(index, true);
    },
    [handleSelect],
  );

  const { activeIndex, setActiveIndex } = useKeyboardSelect({
    active: isActive,
    inputRef,
    onSelect: onKeyboardSelect,
    optionCount: options.length,
  });

  useEffect(() => {
    // Reset index to top on search change
    setActiveIndex(0);
  }, [options, setActiveIndex]);

  return (
    <>
      {options.map((option, index) => (
        <PersonSelectOption
          highlight={index === activeIndex}
          key={`${option.primaryCalendar}-${index}`}
          onMouseDown={() => onMouseSelect(index)}
          onMouseEnter={() => setActiveIndex(index)}
          option={option}
        />
      ))}
    </>
  );
};

type OptionProps = {
  highlight: boolean;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  option: Props["options"][0];
};

export const PersonSelectOption = ({
  highlight,
  onMouseDown,
  onMouseEnter,
  option,
}: OptionProps) => {
  return (
    <div
      tabIndex={0}
      onMouseEnter={onMouseEnter}
      className={classNames(
        "cw-py-1 cw-px-2 cw-cursor-pointer cw-flex cw-items-center cw-rounded cw-mb-1",
        { "cw-bg-default-hover": highlight },
      )}
      onMouseDown={onMouseDown}
    >
      <AttendeeAvatar
        profile={option?.profile || { familyName: "", givenName: "", externalImageUrl: "" }}
        size="small"
      />
      <span className="cw-font-medium cw-body-sm cw-ml-2 cw-mr-1">{`${
        option.profile?.givenName || ""
      } ${option.profile?.familyName || ""}`}</span>
      <span className="cw-text-neutral-muted cw-body-sm cw-text-xs">{option.primaryCalendar}</span>
    </div>
  );
};
