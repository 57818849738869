import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { ResponseStatusEnum } from "@clockwise/schema";
import { DateTime, Interval } from "luxon";
import { SearchEvent } from "../../chat/hooks/useEventsSearch";
import { getAttendeeResponseStatusFromEvent } from "../../web-app-calendar/util/getAttendeeResponseStatusFromEvent";
import { MentionsSearchEventsQuery } from "../apollo/__generated__/MentionsSearchEvents.generated";

export const parseUpcomingEvents = (
  calendarData: MentionsSearchEventsQuery,
  zonedNow: DateTime,
  timeZone: string,
) => {
  const upcomingEvents: SearchEvent[] = [];
  if (calendarData && getValue(calendarData.org)) {
    const calendarEvents = getValue(
      getValue(calendarData?.org, "Org")?.eventParentBaseWrapperByWeeksObject,
    )?.list;

    // find all upcoming and non declined events
    calendarEvents?.forEach?.((week) => {
      week.eventParentsByDay.forEach((byDay) => {
        byDay.eventParents?.forEach((eventParent) => {
          eventParent.events.forEach((e) => {
            if (DateTime.fromISO(e.startTime.dateOrDateTime).setZone(timeZone) > zonedNow) {
              // This assumes that e.calendarIds will always only include the current user's calendar Id
              const responseStatusIsNotDeclined = e.calendarIds.some((calendarId) => {
                const responseStatus = getAttendeeResponseStatusFromEvent(e, calendarId);
                return responseStatus !== ResponseStatusEnum.Declined;
              });

              const startDateTime = DateTime.fromISO(e.startTime.dateOrDateTime, {
                zone: timeZone,
              });
              const endDateTime = DateTime.fromISO(e.endTime.dateOrDateTime, { zone: timeZone });

              const interval = Interval.fromDateTimes(startDateTime, endDateTime);

              const isAllDayEquivalent = e.isAllDay || interval.toDuration().as("days") >= 1;

              if (
                !!e.title &&
                !!e.eventKey.externalEventId &&
                !!e.startTime.dateOrDateTime &&
                responseStatusIsNotDeclined &&
                !isAllDayEquivalent
              ) {
                upcomingEvents.push({
                  title: e.title,
                  externalEventId: e.eventKey.externalEventId,
                  startTime: e.startTime.dateOrDateTime,
                });
              }
            }
          });
        });
      });
    });
  }
  return upcomingEvents;
};
