import { compact, last, map } from "lodash";
import { ProcessMessageGCalMutation } from "../mutations/__generated__/ProcessMessageGCal.generated";

type ProcessMessage = NonNullable<ProcessMessageGCalMutation["processMessage"]>;
type ProcessMessageHistory = NonNullable<ProcessMessage["updatedHistory"]>;
type ProcessMessageHistoryMessages = NonNullable<ProcessMessageHistory["messages"]>;

const getMostRecentConversationId = (
  processMessageData: ProcessMessageGCalMutation | undefined | null,
  previousMessages: ProcessMessageHistoryMessages,
) => {
  const mostRecentConversationId =
    last(
      compact(
        map(previousMessages, (message) => {
          if (message.__typename === "ProposalResponse") {
            return message.conversationId;
          }
          if (message.__typename === "SchedulingLinksResponse") {
            return message.conversationId;
          }
          if (message.__typename === "TextResponse") {
            return message.conversationId;
          }
          if (message.__typename === "ViewEventsResponse") {
            return message.conversationId;
          }
          return null;
        }),
      ),
    ) || processMessageData?.processMessage?.conversationId;

  return mostRecentConversationId;
};

export { getMostRecentConversationId };
