import { useReadCalendarColors } from "#webapp/src/components/web-app-calendar/hooks/CalendarColorsContext";
import { Kbd } from "@clockwise/design-system";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { FocusEventHandler, MouseEventHandler, useEffect } from "react";
import { ChatAction } from "./hooks/useChatActionChip";
import { CalendarIdToNamesMap } from "./hooks/useSelectedCalendarNames";

type Props = {
  options: ChatAction[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onSelect: (index: number) => void;
  multiCalNames: CalendarIdToNamesMap;
};

export const CommandBarActionSelect = ({
  options,
  activeIndex,
  setActiveIndex,
  onSelect,
  multiCalNames,
}: Props) => {
  const calendarColors = useReadCalendarColors();
  useEffect(() => {
    // Reset index to top on search change
    setActiveIndex(0);
  }, [options, setActiveIndex]);

  if (options.length === 0) {
    return null;
  }

  const MultiCalNameString = () => {
    return (
      <>
        {Object.keys(multiCalNames).map((calendarId, index) => {
          const numAttendees = Object.keys(multiCalNames).length;
          const firstName = multiCalNames[calendarId].firstName;
          const color = calendarColors[calendarId]?.fg;
          if (index === numAttendees - 2) {
            return (
              <>
                <span key={calendarId} style={{ color }}>
                  {firstName}
                </span>
                {" and "}
              </>
            );
          }
          if (index === numAttendees - 1) {
            return (
              <span key={calendarId} style={{ color }}>
                {firstName}{" "}
              </span>
            );
          }
          return (
            <>
              <span key={calendarId} style={{ color }}>
                {firstName}
              </span>
              {", "}
            </>
          );
        })}
      </>
    );
  };

  return (
    <div className="cw-m-2">
      {options.map((option, index) => {
        let labelComponent = <span>{option.label}</span>;
        if (!isEmpty(multiCalNames) && option.includeMultiCalSelection) {
          labelComponent = (
            <span>
              {`${option.label} with`} <MultiCalNameString />
            </span>
          );
        }
        return (
          <ActionItem
            highlight={index === activeIndex}
            icon={option.iconOption}
            key={index}
            onMouseDown={() => onSelect(index)}
            onMouseEnter={() => setActiveIndex(index)}
            onFocus={() => setActiveIndex(index)}
            label={option.label}
            labelComponent={labelComponent}
            keyboardShortcut={option.keyboardShortcut}
          />
        );
      })}
    </div>
  );
};

type ActionItemProps = {
  highlight: boolean;
  icon?: React.JSX.Element;
  onMouseDown: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter: MouseEventHandler<HTMLButtonElement>;
  onFocus: FocusEventHandler<HTMLButtonElement>;
  labelComponent: React.ReactElement;
  label: string;
  keyboardShortcut?: string;
};

const ActionItem = ({
  highlight,
  icon,
  onMouseDown,
  onMouseEnter,
  onFocus,
  labelComponent,
  label,
  keyboardShortcut,
}: ActionItemProps) => {
  return (
    <button
      aria-label={label}
      onMouseEnter={onMouseEnter}
      className={classNames(
        "cw-flex cw-items-center cw-gap-3",
        "cw-w-full cw-py-2 cw-px-3",
        "cw-rounded-xl",
        "cw-border-none",
        "cw-cursor-pointer",
        { "cw-bg-transparent": !highlight },
        { "cw-bg-default-hover": highlight },
      )}
      onMouseDown={onMouseDown}
      onClick={onMouseDown}
      onFocus={onFocus}
    >
      {icon}
      <span className="cw-text-default cw-text-14 cw-font-medium">{labelComponent}</span>
      {keyboardShortcut && (
        <Kbd className="cw-ml-auto cw-text-muted cw-leading-3" size="xs">
          {keyboardShortcut}
        </Kbd>
      )}
    </button>
  );
};
