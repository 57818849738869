import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import { DateTime } from "luxon";

export const formatEventMentionStartTime = (startTimeISO: string) => {
  const startTime = DateTime.fromISO(startTimeISO, {
    zone: getRenderTimeZone(),
  });
  const now = DateTime.local({ zone: getRenderTimeZone() });
  let formattedStartDate = startTime.toFormat("MM/dd");

  const isToday = startTime.startOf("day").equals(now.startOf("day"));
  const isTomorrow = startTime.startOf("day").equals(now.plus({ days: 1 }).startOf("day"));
  if (isToday) {
    formattedStartDate = "Today";
  } else if (isTomorrow) {
    formattedStartDate = "Tmw";
  }

  const formattedStartTime = startTime
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/\s/g, "")
    .toLowerCase();

  return `${formattedStartDate} ${formattedStartTime}`;
};
