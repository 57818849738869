import { CHAT_TEXT_INPUT_ID } from "../ChatInput/ChatInputTextArea";

export const useFocusChatTextInput = () => {
  const focusChatTextInput = (timeout?: number) => {
    setTimeout(() => {
      const chatInputTextArea = document.getElementById(CHAT_TEXT_INPUT_ID);

      if (!chatInputTextArea) return;

      chatInputTextArea.focus();
    }, timeout ?? 50);
  };

  return focusChatTextInput;
};
