import { Button } from "@clockwise/design-system";
import { TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { track } from "../../../util/analytics.util";
import { useUpdateSuggestedPrompt } from "../ai-chat/hooks/SuggestedPromptContext";

const SUGGESTED_PROMPTS = ["Schedule @", "Reschedule #", "Create link"];

export const ChatInputSuggestions = () => {
  const updatePromptText = useUpdateSuggestedPrompt();

  const setupInputSuggestion = (prompt: string) => {
    updatePromptText(prompt);
    track(TrackingEvents.CHAT.CHAT_RECOMMENDATION_ABOVE_INPUT_CLICKED, { text: prompt });
  };

  return (
    <div className="cw-flex cw-items-center cw-gap-2 cw-px-4 cw-mb-2 cw-absolute cw-top-0">
      {SUGGESTED_PROMPTS.map((prompt) => {
        return (
          <Button
            key={prompt}
            sentiment="neutral"
            variant="outlined"
            size="mini"
            onClick={() => {
              setupInputSuggestion(prompt);
            }}
          >
            {prompt}
          </Button>
        );
      })}
    </div>
  );
};
