// This function is used to get the index the current row the user is on.
// Here we grab the selection's nodeValue (AKA the text in the row)
// Used to watch for whether a mention is being typed
const getCaretIndexWithRowText = () => {
  let currentTextOfSelectionRow = "";
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection) {
      // Check if there is a selection (i.e. cursor in place)
      if (selection.rangeCount !== 0) {
        currentTextOfSelectionRow = selection.anchorNode?.nodeValue || "";
      }
    }
  }
  return { text: currentTextOfSelectionRow };
};

const isSpace = (char: string) => char === " " || char === String.fromCharCode(160);

const isNewLine = (char: string) => char === "\n" || char === String.fromCharCode(10);

export type MentionSearch = {
  isEmptyAtSymbol: boolean;
  isAMention: boolean;
  wordToSearch: string;
  symbol: "@" | "#" | undefined;
};

// Check if you are on a word with an @ or # in the beginning
// Return the word without the @ or #
export const fetchPotentialMention = (optionalPrompt?: string): MentionSearch => {
  const { text: currentText } = getCaretIndexWithRowText();

  const text = optionalPrompt || currentText;

  if (text.includes("@") || text.includes("#")) {
    let isEmptyAtSymbol = false;
    let wordToSearch = "";
    let isAMention = false;

    const atIndex = text.lastIndexOf("@");
    const hashtagIndex = text.lastIndexOf("#");
    const symbolIndex = Math.max(atIndex, hashtagIndex);
    let symbol: MentionSearch["symbol"] = undefined;

    if (atIndex > hashtagIndex) {
      symbol = "@";
    } else {
      symbol = "#";
    }

    if (symbolIndex === 0) {
      //is not a mention if there is a space or newline direclty after the @
      if (text.length === 0) {
        isEmptyAtSymbol = true;
        isAMention = true;
        wordToSearch = text.slice(symbolIndex + 1).trim();
      } else {
        const nextChar = text.charAt(symbolIndex + 1);
        if (!isSpace(nextChar) && !isNewLine(nextChar)) {
          isEmptyAtSymbol = symbolIndex === text.length - 1;
          isAMention = true;
          wordToSearch = text.slice(symbolIndex + 1).trim();
        }
      }
    } else if (
      // if the character before the @ is a space, and character after is NOT a space or newline, it's a mention
      isSpace(text.charAt(symbolIndex - 1)) &&
      !isSpace(text.charAt(symbolIndex + 1)) &&
      !isNewLine(text.charAt(symbolIndex + 1))
    ) {
      // empty if it's the last character of the string
      isEmptyAtSymbol = symbolIndex === text.length - 1;
      isAMention = true;
      wordToSearch = text.slice(symbolIndex + 1).trim();
    }

    // If there's a newline within the word to search, not a mention
    if (wordToSearch.includes("\n")) {
      wordToSearch = "";
      isAMention = false;
    }
    return {
      isEmptyAtSymbol,
      isAMention,
      wordToSearch,
      symbol,
    };
  }
  return {
    isEmptyAtSymbol: false,
    isAMention: false,
    wordToSearch: "",
    symbol: undefined,
  };
};
