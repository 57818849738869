import { noop } from "lodash";
import React, { ReactNode, createContext, useCallback, useContext, useState } from "react";
import invariant from "tiny-invariant";

type PromptSource = "button" | "keyboard-shortcut";

type SuggestedPrompt = {
  promptText: string | null;
  promptSource: PromptSource | null;
};

const ReadContext = createContext<SuggestedPrompt | null>(null);
const WriteContext = createContext<
  (promptText: string | null, promptSource?: PromptSource) => void
>(noop);

type ProviderProps = {
  children: ReactNode;
  initValue: SuggestedPrompt | null;
};
export const SuggestedPromptProvider = ({ children, initValue = null }: ProviderProps) => {
  const [suggestedPrompt, setSuggestedPrompt] = useState<SuggestedPrompt | null>(initValue);

  const handleSetSuggestedPrompt = useCallback(
    (promptText: string | null, promptSource: PromptSource = "button") => {
      setSuggestedPrompt({ promptText, promptSource });
    },
    [],
  );

  return (
    <WriteContext.Provider value={handleSetSuggestedPrompt}>
      <ReadContext.Provider value={suggestedPrompt}>{children}</ReadContext.Provider>
    </WriteContext.Provider>
  );
};

export const useReadSuggestedPrompt = () => useContext(ReadContext);
export const useUpdateSuggestedPrompt = () => {
  const context = React.useContext(WriteContext);
  invariant(context, "useUpdateSuggestedPrompt must be within SuggestedPromptProvider");
  return context;
};
