import { CalendarColorSet } from "@clockwise/web-commons/src/util/calendar-coloring";
import React, { useCallback } from "react";

export const useColorCoding = ({ inputRef }: { inputRef: React.RefObject<HTMLDivElement> }) => {
  const onChange = useCallback(
    (calendarColorMap: Partial<Record<string, CalendarColorSet>>) => {
      const input = inputRef.current;

      if (!input) {
        return;
      }

      input.childNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          if (element.dataset.color_id) {
            const primaryCalendar = atob(element.dataset.color_id);
            const label = element.querySelector(".label");
            const color = calendarColorMap?.[primaryCalendar];
            if (color) {
              element.style.borderColor = color.bg;
              if (label) {
                label.setAttribute("style", `color: ${color.fg}`);
              }
            }
          }
        }
      });
    },
    [inputRef],
  );

  return {
    onChange,
  };
};
