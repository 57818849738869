import { FindTime } from "@clockwise/design-system/icons";
import { fg_info } from "@clockwise/design-system/tokens";
import { AddLink } from "@clockwise/icons";
import { RescheduleAI } from "@clockwise/web-commons/src/components/svgs/RescheduleAI";
import React from "react";

export const rescheduleOptionIcon = <RescheduleAI fill={fg_info} className="cw-h-4 cw-w-4" />;
export const scheduleOptionIcon = <FindTime className="cw-text-brand cw-h-4 cw-w-4" />;
export const createLinkOptionIcon = <AddLink className="cw-text-busy cw-h-4 cw-w-4" />;
export const optimizeOptionIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_2787_57" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2787_57)">
      <path
        d="M13.9999 8C14.0012 8.20378 13.9393 8.40295 13.8227 8.57008C13.7061 8.73723 13.5405 8.86415 13.3488 8.93335L10.1258 10.1241L8.93854 13.349C8.86823 13.5399 8.74103 13.7048 8.57409 13.8212C8.40716 13.9376 8.20853 14 8.005 14C7.80145 14 7.60282 13.9376 7.43589 13.8212C7.26894 13.7048 7.14174 13.5399 7.07143 13.349L5.87671 10.1241L2.65115 8.93712C2.46015 8.86682 2.29531 8.73965 2.17887 8.57274C2.06243 8.40584 2 8.20725 2 8.00376C2 7.80026 2.06243 7.60166 2.17887 7.43477C2.29531 7.26786 2.46015 7.14069 2.65115 7.07039L5.87671 5.87591L7.06394 2.65101C7.13425 2.46005 7.26145 2.29524 7.42838 2.17883C7.59533 2.06242 7.79396 2 7.99749 2C8.20102 2 8.39965 2.06242 8.5666 2.17883C8.73353 2.29524 8.86072 2.46005 8.93103 2.65101L10.1258 5.87591L13.3513 7.0629C13.5432 7.13274 13.7086 7.26041 13.8248 7.42826C13.941 7.59612 14.0022 7.7959 13.9999 8Z"
        fill="url(#paint0_linear_2787_57)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2787_57"
        x1="13.7143"
        y1="8"
        x2="2.28571"
        y2="13.7143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#444CE7" />
        <stop offset="1" stopColor="#FED2FC" />
      </linearGradient>
    </defs>
  </svg>
);
