import classNames from "classnames";
import React, { FC, ReactNode, useEffect } from "react";
import { insertTextToDOM } from "./utils/insertTextToDOM";

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  inputRef: React.RefObject<HTMLDivElement>;
  onBlur?: () => void;
  onChange?: () => void;
  onFocus?: () => void;
  onSubmit: () => void;
  placeholder?: string;
  submitOnEnter?: boolean;
  children?: ReactNode;
  size?: "small" | "large";
};

const isSubmitKey = (e: React.KeyboardEvent<HTMLDivElement>) => e.key === "Enter" && !e.shiftKey;

export const CHAT_TEXT_INPUT_ID = "chat-text-input";
export const ChatInputTextArea: FC<Props> = ({
  autoFocus = false,
  children,
  disabled,
  inputRef,
  onBlur,
  onChange,
  onFocus,
  onSubmit,
  placeholder,
  submitOnEnter = true,
  size = "small",
}) => {
  useEffect(() => {
    // Auto focus chat input on mount
    if (!!inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isSubmitKey(e)) {
      if (submitOnEnter) {
        onSubmit();
      }
      e.preventDefault();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isSubmitKey(e)) {
      onChange?.();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const text = e.clipboardData.getData("text");
    if (insertTextToDOM(text)) {
      onChange?.();
      e.preventDefault();
    }
  };

  return (
    <div
      aria-label="scheduler text input"
      aria-multiline="true"
      className={classNames(
        "cw-grow cw-content-center",
        "cw-overflow-hidden",
        "cw-text-default cw-leading-6",
        "cw-border-none",
        "focus:cw-ring-0 focus:cw-ring-offset-0 focus:cw-outline-none",
        "cw-py-2.5 cw-px-3",
        {
          "cw-cursor-not-allowed cw-border-default-disabled": disabled,
          "cw-body-sm": size === "small",
          "cw-body-lg": size === "large",
        },
      )}
      style={{ caretColor: "#00A972" }}
      contentEditable={!disabled}
      cw-id={CHAT_TEXT_INPUT_ID}
      id={CHAT_TEXT_INPUT_ID}
      data-enable-grammarly="false"
      data-gramm_editor="false"
      data-gramm="false"
      data-placeholder={placeholder}
      dir="auto"
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onPaste={handlePaste}
      ref={inputRef}
      role="textbox"
      spellCheck={false}
      suppressContentEditableWarning
      tabIndex={0}
    >
      {children}
    </div>
  );
};
