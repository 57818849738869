import { logger } from "#webapp/src/util/logger.util";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { noop } from "lodash";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import invariant from "tiny-invariant";
import { toastStyles } from "../../util/toastStyles";

interface AIErrorType {
  showUserMessage: boolean;
  message: string;
  error: Error;
  userMessage?: string;
}

const AIErrorContext = createContext<(error: AIErrorType | null) => void>(() => noop);

const useErrorForProvider = () => {
  const [errorWrapper, setErrorWrapper] = useState<AIErrorType | null>(null);
  const track = useTracking();

  useEffect(() => {
    if (!!errorWrapper) {
      const { message, showUserMessage, userMessage, error } = errorWrapper;

      track(TrackingEvents.CHAT.ERROR, { message: message, error: error });
      logger.error(`AI ERROR: ${message} - ${error.message}`, error);

      if (showUserMessage) {
        toast.error(!!userMessage ? userMessage : message, {
          position: "bottom-center",
          style: toastStyles,
        });
      }
    }
  }, [errorWrapper]);

  const setError = (error: AIErrorType) => {
    setErrorWrapper(() => error);
  };

  return setError;
};
export function AIErrorProvider({ children }: PropsWithChildren<{}>) {
  const value = useErrorForProvider();

  return <AIErrorContext.Provider value={value}>{children}</AIErrorContext.Provider>;
}

export function useSetAIError() {
  const context = useContext(AIErrorContext);
  invariant(context, "useSetAIError must be within AIErrorProvider");
  return context;
}
