import { RecurrenceIntervalTypeEnum } from "@clockwise/schema";

export const feedbackURL = "https://form.jotform.com/231205475414045";
export const changelogURL =
  "https://getclockwise.helpscoutdocs.com/article/84-clockwise-release-notes";
export const aiSchedulerRoadmapURL =
  "https://clockwise.notion.site/da3a46e707664c1487b3579e39ba1549?v=1d163d79eace483fbd73e6b3ee575b31";

export const loadingMessages = [
  "Got it! Working on your request now.",
  "On it. Also, did you know you can ask me to handle multiple tasks with just one prompt?",
  "Hold tight, I'm on it.",
  "On it! By the way, have you tried using <span class='cw-text-busy cw-font-medium'>“@”</span> to mention specific people?",
  "Tackling your request, one moment!",
  "Alright, give me a few seconds to get this done.",
  "I'm on it! Just a second.",
  "One moment, let me handle that for you.",
  "Working on it. Also, you can ask me to create a scheduling link next time you need one.",
  "Received, working on this now.",
  "Looking into it now, stay tuned!",
  "Hold tight. By the way, the more meetings you mark as flexible, the more options I have when you're scheduling.",
  "Acknowledged! I'll have an update for you shortly.",
  "Checking your calendar, I'll have something for you soon.",
  "While I get that ready, did you know Clockwise runs an org-wide optimization everyday at 4pm?",
  "Hang tight, I'll get back with you shortly.",
  "Just a second, I'm working on it.",
  "While I get that ready, did you know you can click an event in the calendar to see AI-enabled workflows, like rescheduling?",
  "Working on it. By the way, you can use <span class='cw-text-busy cw-font-medium'>“#”</span> to mention specific events.",
];

export const defaultNoRecurrence = {
  intervalType: RecurrenceIntervalTypeEnum.NONE,
  intervalCount: 0,
  recurrenceString: "None",
};

const RENDERED_IN = {
  AI_CHAT: "AI_CHAT",
  SHARED_PROPOSAL: "SHARED_PROPOSAL",
};
export type ProposalRenderSurface = keyof typeof RENDERED_IN;

export const UNABLE_TO_LOAD_CALENDAR_DATA = "Something went wrong loading your calendar.";

export const AI_EXCLUSION_TOOLTIP =
  "Your account admin has disabled AI functionality for your organization. Please contact your admin for help.";

export const AI_IMPERSONATION_TOOLTIP =
  "Chat user input and calendar event manipulation are deactivated because you are impersonating a user via sudo";
