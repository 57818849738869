import { useMutation } from "@apollo/client";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import { useDispatch } from "react-redux";
import { clearCalendarIds } from "../../../state/actions/multi-calendar.actions";
import { CancelProcessMessageDocument } from "../ai-chat/apollo/__generated__/CancelProcessMessage.generated";
import { useSetAIError } from "../ai-chat/hooks/AIErrorContext";
import { useAIMessageContext } from "../ai-chat/hooks/AIMessageContext";
import { tempCancelAssistantMessage } from "../ai-chat/utils/getTempAssistantMessage";

export const useCancelProcessMessage = (onCompletedCallback?: (timeout?: number) => void) => {
  const dispatch = useDispatch();

  const {
    setProcessingMessage,
    setCancelProcessing,
    chatHistoryId,
    loadingHistory,
    messages,
    reprocessingMessage,
    shouldExcludeAi,
  } = useAIMessageContext();
  const track = useTracking();
  const setError = useSetAIError();
  const [cancelProcessMessageMutation] = useMutation(CancelProcessMessageDocument, {
    onError: (error) => {
      setError({
        error,
        message: "Failed to cancel process message",
        showUserMessage: true,
      });
    },
    onCompleted: () => {
      onCompletedCallback?.();
      setProcessingMessage(false);
      setCancelProcessing(true); // only trigger AbortController on success
      dispatch(clearCalendarIds()); // remove any multi cal views
    },
  });

  const cancelProcessMessage = () => {
    const assistantMessage = tempCancelAssistantMessage;

    // Don't add optimistic message if the chat history isn't loaded, or if it's canceling a reprocess message
    // Reprocess message cancelations do not return a text assistant message, but the original proposal
    const appendOptimisticMessage = chatHistoryId && !loadingHistory && !reprocessingMessage;

    reprocessingMessage
      ? track(TrackingEvents.CHAT.CANCEL.CANCEL_REPROCESS_MESSAGE)
      : track(TrackingEvents.CHAT.CANCEL.CANCEL_PROCESS_MESSAGE);

    void cancelProcessMessageMutation({
      optimisticResponse: appendOptimisticMessage
        ? {
            __typename: "Mutation",
            cancelProcessMessage: {
              __typename: "CancelProcessMessagePayload",
              debugInfo: "",
              conversationId: "",
              updatedHistory: {
                __typename: "ChatHistory",
                id: chatHistoryId,
                shouldExcludeAi,
                isProcessing: false,
                messages: [...messages, assistantMessage],
              },
            },
          }
        : undefined,
    });
  };

  return cancelProcessMessage;
};
