import { LegacyTooltip } from "@clockwise/web-commons/src/ui/tooltip";
import classNames from "classnames";
import React from "react";

export const BetaLabel = () => {
  const header = "We're in beta!";
  const body = "Chat scheduling is in beta. Please report any errors or unexpected results.";

  return (
    <LegacyTooltip showArrow={true} placement={"bottom"} width={200} header={header} body={body}>
      <div
        className={classNames(
          "cw-text-11",
          "cw-text-subtle",
          "cw-font-semibold",
          "cw-border",
          "cw-border-solid",
          "cw-border-gray-400",
          "cw-rounded-md",
          "cw-py-[2px]",
          "cw-px-[4px]",
          "cw-mr-3",
        )}
      >
        Beta
      </div>
    </LegacyTooltip>
  );
};
