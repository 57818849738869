export const generateRescheduleCQL = (externalEventId?: string) => {
  if (!externalEventId) {
    // Cannot generate CQL without an externalEventId
    return null;
  }
  return `(update_events (find_events (event_id "${externalEventId}")) (event_mutation (when (anchor (date_merge (nearby))))))`;
};

export const generateRescheduleDurationAndFindMoreTimesCQL = (
  externalEventId: string,
  durationInMinutes: number,
) => {
  return `(update_events (find_events (event_id "${externalEventId}")) (event_mutation (duration (delta (minute ${durationInMinutes}))) (when (anchor (date_merge (nearby))))))`;
};

export const generateRescheduleDurationAtExactTimeCQL = (
  externalEventId: string,
  durationInMinutes: number,
) => {
  return `(update_events (find_events (event_id "${externalEventId}")) (event_mutation (duration (delta (minute ${durationInMinutes})))))`;
};

export const generateScheduleCQL = (calendarIds: string[], durationInMinutes?: number) => {
  if (calendarIds.length === 0) {
    // Cannot generate CQL without calendarIds
    return null;
  }
  const attendeesString = calendarIds.map((calendarId) => `"${calendarId}"`).join(" ");
  if (!durationInMinutes) {
    return `(create_event (attendees ${attendeesString}))`;
  } else {
    return `(create_event (attendees ${attendeesString}) (duration (delta (minute ${durationInMinutes}))))`;
  }
};
