import { EventCardAttendee } from "#webapp/src/components/event-card/types.js";
import { Size } from "@clockwise/design-system/src/constants/types";
import { EventMention, PersonMention } from "@clockwise/schema";
import { getFullName } from "@clockwise/web-commons/src/util/profile.util";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import usePlannerMetaData from "../../../../web-app-calendar/hooks/usePlannerMetaData";
import { SearchEvent, useEventsSearch } from "../../../hooks/useEventsSearch";
import { useSearchPersonsChat } from "../../../hooks/useSearchPersons";
import { chipAsNode } from "../ChatInputChip";
import { fetchPotentialMention, MentionSearch } from "../utils/fetchPotentialMention";

export type Mentions = {
  personMentions: PersonMention[];
  eventMentions: EventMention[];
};
const emptyMentions: Mentions = {
  personMentions: [],
  eventMentions: [],
};

type Props = {
  ref: React.RefObject<HTMLDivElement>;
  onSelect?: (mentions: Mentions) => void;
  onUnselect?: (mentions: Mentions) => void;
  size?: Size;
};

export const useMentionChips = ({ ref, onSelect, onUnselect, size = "mini" }: Props) => {
  const [mentions, setMentions] = useState<Mentions>(emptyMentions);
  const mentionSearch = useRef<MentionSearch | null>(null);
  const { primaryCalendarId } = usePlannerMetaData();

  const {
    searchPersons: personMentionOptions,
    handlePersonSearch,
    resetSearch: resetPersonSearch,
    setShowEmptyState: setShowEmptyPersonState,
  } = useSearchPersonsChat({ searchPersonLimit: 10 });

  const {
    handleEventSearch,
    resetSearchEvents,
    searchEvents: eventMentionOptions,
    setShowEventSearchEmptyState,
  } = useEventsSearch(primaryCalendarId);

  const sortByName = (a: EventCardAttendee, b: EventCardAttendee) =>
    a.profile?.givenName?.localeCompare(b.profile?.givenName || "") || 0;
  const avaliabePersonMentionOptions = useMemo(() => {
    if (mentionSearch.current?.isEmptyAtSymbol) {
      return personMentionOptions
        .filter(
          (person) =>
            !mentions.personMentions.some(
              (mention) => mention.calendarId === person.primaryCalendar,
            ),
        )
        .slice(0, 5)
        .sort(sortByName);
    }
    return personMentionOptions.slice(0, 5).sort(sortByName);
  }, [mentions.personMentions, personMentionOptions]);

  const sortByStartTime = (a: SearchEvent, b: SearchEvent) =>
    a.startTime.localeCompare(b.startTime);
  const avaliabeEventMentionOptions = useMemo(() => {
    if (mentionSearch.current?.isEmptyAtSymbol) {
      return eventMentionOptions
        .filter(
          (event) =>
            !mentions.eventMentions.some(
              (mention) => mention.externalEventId === event.externalEventId,
            ),
        )
        .sort(sortByStartTime);
    }
    return eventMentionOptions.sort(sortByStartTime);
  }, [eventMentionOptions, mentions.eventMentions]);

  const insertPersonChipToRef = useCallback(
    (person: { primaryCalendar: string; name: string }) => {
      chipAsNode(
        {
          chipType: "mention",
          id: btoa(person.primaryCalendar),
          colorId: btoa(person.primaryCalendar),
          size: size,
          text: person.name,
          variant: "default",
        },
        (node) => {
          if (ref.current && mentionSearch.current && node) {
            const innerChat = ref.current.innerHTML;
            const mentionBeingAdded =
              mentionSearch.current.symbol + mentionSearch.current.wordToSearch;
            const splitChatByMention = innerChat.split(mentionBeingAdded);
            const newValue = `${splitChatByMention[0]}${node.parentElement?.innerHTML}${
              splitChatByMention?.[1] || "&nbsp;"
            }`;

            ref.current.innerHTML = newValue;
          }
        },
      );
    },
    [ref, size],
  );

  const insertPersonChipToRefBypassSearch = useCallback(
    (person: { primaryCalendar: string; name: string }) => {
      chipAsNode(
        {
          chipType: "mention",
          id: btoa(person.primaryCalendar),
          colorId: btoa(person.primaryCalendar),
          size: size,
          text: person.name,
          variant: "default",
        },
        (node) => {
          if (ref.current && node) {
            const innerChat = ref.current.innerHTML;
            const mentionBeingAdded = "@";
            const splitChatByMention = innerChat.split(mentionBeingAdded);
            const newValue = `${splitChatByMention[0]}${node.parentElement?.innerHTML}${splitChatByMention?.[1]} @`;

            ref.current.innerHTML = newValue;
          }
        },
      );
    },
    [ref, size],
  );

  const insertEventChipToRef = useCallback(
    (event: SearchEvent) => {
      chipAsNode(
        {
          chipType: "mention",
          id: btoa(event.externalEventId),
          size: size,
          text: event.title,
          variant: "default",
        },
        (node) => {
          if (ref.current && mentionSearch.current && node) {
            const innerChat = ref.current.innerHTML;
            const mentionBeingAdded =
              mentionSearch.current.symbol + mentionSearch.current.wordToSearch;

            // DEV NOTE: finding by last index is potentially fragile, if this breaks
            //           we may need to track the position of the mention in the text
            const preText = innerChat.slice(0, innerChat.lastIndexOf(mentionBeingAdded));
            const postText =
              innerChat.slice(
                innerChat.lastIndexOf(mentionBeingAdded) + mentionBeingAdded.length,
              ) || "&nbsp;";

            const newValue = `${preText}${node.parentElement?.innerHTML}${postText}`;

            ref.current.innerHTML = "";
            ref.current.innerHTML = newValue;
          }
        },
      );
    },
    [ref],
  );

  const showEmptySearch = useCallback(
    (isEmptyState: boolean) => {
      if (mentionSearch.current?.symbol === "#") {
        resetPersonSearch();
      } else {
        setShowEmptyPersonState(isEmptyState);
      }
      setShowEventSearchEmptyState(isEmptyState);
    },
    [resetPersonSearch, setShowEmptyPersonState, setShowEventSearchEmptyState],
  );

  const resetSearch = useCallback(() => {
    resetPersonSearch();
    resetSearchEvents();
  }, [resetPersonSearch, resetSearchEvents]);

  const handleCancel = useCallback(() => {
    mentions.personMentions.forEach((mention) => {
      onUnselect?.({ personMentions: [mention], eventMentions: [] });
    });
    mentions.eventMentions.forEach((mention) => {
      onUnselect?.({ eventMentions: [mention], personMentions: [] });
    });
    setMentions(emptyMentions);
    setShowEmptyPersonState(false);
    resetSearch();
    mentionSearch.current = null;
  }, [
    mentions.eventMentions,
    mentions.personMentions,
    onUnselect,
    resetSearch,
    setShowEmptyPersonState,
  ]);

  const handleRemove = useCallback(
    ({ element }: { element: HTMLElement }) => {
      const chipId = atob(element.dataset.chip_id || "");
      const removedPersons = mentions.personMentions.filter(
        (mention) => mention.calendarId === chipId,
      );
      const removedEvents = mentions.eventMentions.filter(
        (mention) => mention.externalEventId === chipId,
      );
      setMentions((prev) => ({
        ...prev,
        personMentions: prev.personMentions.filter((mention) => mention.calendarId !== chipId),
        eventMentions: prev.eventMentions.filter((mention) => mention.externalEventId !== chipId),
      }));
      onUnselect?.({ personMentions: removedPersons, eventMentions: removedEvents });
    },
    [mentions.eventMentions, mentions.personMentions, onUnselect],
  );

  const handleMentionSearch = useCallback(
    (input: string) => {
      if (mentionSearch.current?.symbol === "#") {
        resetPersonSearch();
      } else {
        handlePersonSearch(input, []);
      }
      handleEventSearch(input);
    },
    [handleEventSearch, handlePersonSearch, resetPersonSearch],
  );

  const handleSelectEvent = useCallback(
    (event: SearchEvent) => {
      const mention = {
        externalEventId: event.externalEventId,
        startTime: event.startTime,
        title: event.title,
      };
      setMentions((prev) => ({
        ...prev,
        eventMentions: [...prev.eventMentions, mention],
      }));

      insertEventChipToRef(event);
      resetSearch();
      onSelect?.({ eventMentions: [mention], personMentions: [] });
    },
    [insertEventChipToRef, onSelect, resetSearch],
  );

  const handleAddMultiCalIdToMentions = useCallback(
    (person: { calendarId: string; name: string }) => {
      const mention = {
        calendarId: person.calendarId,
        name: person.name,
      };
      setMentions((prev) => ({
        ...prev,
        personMentions: [...prev.personMentions, mention],
      }));

      insertPersonChipToRefBypassSearch({ primaryCalendar: person.calendarId, name: person.name });
    },
    [insertPersonChipToRefBypassSearch],
  );

  const handleSelectPerson = useCallback(
    (person: { primaryCalendar: string; profile: { givenName: string } }) => {
      const mention = {
        calendarId: person.primaryCalendar,
        name: getFullName(person.profile),
      };
      setMentions((prev) => ({
        ...prev,
        personMentions: [...prev.personMentions, mention],
      }));

      insertPersonChipToRef({
        primaryCalendar: person.primaryCalendar,
        name: getFullName(person.profile),
      });
      resetSearch();
      onSelect?.({ eventMentions: [], personMentions: [mention] });
    },
    [insertPersonChipToRef, onSelect, resetSearch],
  );

  const evalInput = useCallback(
    (input: HTMLDivElement | null) => {
      mentionSearch.current = fetchPotentialMention(input?.innerText || "");
      if (!mentionSearch.current.isAMention) {
        resetSearch();
      } else if (isEmpty(mentionSearch.current.wordToSearch)) {
        showEmptySearch(true);
      } else {
        handleMentionSearch(mentionSearch.current.wordToSearch);
        showEmptySearch(false);
      }
    },
    [handleMentionSearch, resetSearch, showEmptySearch],
  );

  return {
    evalInputMentions: evalInput,
    eventMentionOptions: avaliabeEventMentionOptions,
    mentions,
    onCancelMentions: handleCancel,
    onMentionRemoved: handleRemove,
    onSelectEvent: handleSelectEvent,
    onSelectPerson: handleSelectPerson,
    personMentionOptions: avaliabePersonMentionOptions,
    searchSymbol: mentionSearch.current?.symbol,
    onAddMultiCalIdToMentions: handleAddMultiCalIdToMentions,
  };
};
