import { StopRounded } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";

export const CancelProcessMessageButton = ({ onCancel }: { onCancel: () => void }) => {
  const handleClick = () => {
    onCancel();
  };

  return (
    <div
      tabIndex={0}
      onClick={handleClick}
      className={classNames(
        "cw-group cw-cursor-pointer cw-rounded cw-px-1.5 cw-h-5",
        "cw-flex cw-items-center cw-justify-between",
        "cw-cursor-pointer",
        "hover:cw-bg-neutral-inset",
        "cw-text-muted",
      )}
      aria-label="Stop message request"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleClick();
        }
      }}
    >
      <div
        className={classNames(
          "cw-caption cw-hidden cw-mr-1 cw-ml-0.5",
          "group-hover:cw-inline",
          "cw-text-muted",
        )}
      >
        Stop
      </div>
      <StopRounded className="cw-h-5 cw-w-5 cw-text-brand" />
    </div>
  );
};
