import { EventCardAttendee } from "#webapp/src/components/event-card/types.js";
import { Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { SearchEvent } from "../../../hooks/useEventsSearch";
import { ChatAction } from "../hooks/useChatActionChip";
import { ActionSelect } from "./ActionSelect";
import { EventSelect } from "./EventSelect";
import { PersonSelect } from "./PersonSelect";

const ABOVE_EVERYTHING = 10000;

type Props = {
  chatActionOptions: ChatAction[];
  containerRef: React.RefObject<HTMLDivElement>;
  eventMentionOptions: SearchEvent[];
  inputRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  onSelectChatAction: (action: ChatAction | null) => void;
  onSelectEvent: (event: SearchEvent | null, fromMouseEvent: boolean) => void;
  onSelectPerson: (
    person: { primaryCalendar: string; profile: { givenName: string } } | null,
    fromMouseEvent: boolean,
  ) => void;
  personMentionOptions: EventCardAttendee[];
  searchSymbol: "/" | "@" | "#" | undefined;
};

export const ChatInputModal = ({
  chatActionOptions,
  containerRef,
  eventMentionOptions,
  inputRef,
  isOpen,
  onSelectChatAction,
  onSelectEvent,
  onSelectPerson,
  personMentionOptions,
  searchSymbol,
}: Props) => {
  const [modalEl, setModalEl] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update: updatePopper } = usePopper(containerRef.current, modalEl, {
    placement: "top-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "flip",
        enabled: true,
      },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  const onResize = useDebounceCallback(() => {
    void updatePopper?.();
  }, 200);

  useResizeObserver({
    ref: inputRef,
    onResize,
  });

  if (
    !searchSymbol ||
    (searchSymbol === "/" && isEmpty(chatActionOptions)) ||
    (searchSymbol === "@" && isEmpty(personMentionOptions)) ||
    (searchSymbol === "#" && isEmpty(eventMentionOptions))
  ) {
    return null;
  }

  return (
    <Portal>
      <Transition
        show={isOpen}
        leave="cw-transition cw-ease-in cw-duration-100"
        leaveFrom="cw-opacity-100"
        leaveTo="cw-opacity-0"
        enter="cw-transition cw-ease-in cw-duration-75"
        enterFrom="cw-opacity-0"
        enterTo="cw-opacity-100"
      >
        <div
          ref={setModalEl}
          style={{
            ...styles.popper,
            minWidth: containerRef.current?.scrollWidth || 0,
            zIndex: ABOVE_EVERYTHING,
          }}
          {...attributes.popper}
          className={classNames(
            "cw-p-2 cw-shadow-md cw-rounded-lg cw-absolute cw-body-base cw-bg-default",
            "cw-border cw-border-solid cw-border-default",
          )}
        >
          {searchSymbol === "/" && (
            <ActionSelect
              inputRef={inputRef}
              onSelect={onSelectChatAction}
              options={chatActionOptions}
            />
          )}
          {searchSymbol === "@" && (
            <PersonSelect
              inputRef={inputRef}
              onSelect={onSelectPerson}
              options={personMentionOptions}
            />
          )}
          {searchSymbol === "#" && (
            <EventSelect
              inputRef={inputRef}
              onSelect={onSelectEvent}
              options={eventMentionOptions}
            />
          )}
        </div>
      </Transition>
    </Portal>
  );
};
