import { useQuery } from "@apollo/client";
import { useReadCalendar } from "@clockwise/web-commons/src/util/CalendarContext";
import { getPreviousWeekday } from "@clockwise/web-commons/src/util/getPreviousWeekday";
import { getRenderTimeZone } from "@clockwise/web-commons/src/util/time-zone.util";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import { isEmpty } from "lodash/fp";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useOrgId } from "../../hooks/useOrgId";
import {
  MentionsSearchEventsDocument,
  MentionsSearchEventsQuery,
} from "../apollo/__generated__/MentionsSearchEvents.generated";
import { parseCalendarEventsForMentionsGQL } from "../util/parseCalendarEventsForMentionsGQL";
import { parseUpcomingEvents } from "../util/parseUpcomingEvents";
export type SearchEvent = {
  title: string;
  externalEventId: string;
  startTime: string;
};

export const useEventsSearch = (calendarId?: string) => {
  const { focusedDate } = useReadCalendar();
  const timeZone = getRenderTimeZone();
  const weekStartDateThisWeek = getPreviousWeekday(
    DateTime.fromISO(focusedDate),
    "sunday",
  ).toISODate();
  const weekStartDateNextWeek = getPreviousWeekday(DateTime.fromISO(focusedDate), "sunday")
    .plus({ weeks: 1 })
    .toISODate();
  const { orgId } = useOrgId();
  const [events, setEvents] = useState<SearchEvent[]>([]);
  const [searchEvents, setSearchEvents] = useState<SearchEvent[]>([]);
  const [showUpcomingEvents, setShowUpcomingEvents] = useState<boolean>(false);

  const [upcomingEvents, setUpcomingEvents] = useState<SearchEvent[]>([]);

  useEffect(() => {
    if (showUpcomingEvents) {
      setSearchEvents(upcomingEvents);
    }
  }, [showUpcomingEvents, upcomingEvents]);

  const setShowEventSearchEmptyState = useCallback(
    (emptyState: boolean) => {
      setShowUpcomingEvents(emptyState);
    },
    [setShowUpcomingEvents],
  );

  const parseAndAddEvents = useCallback(
    (data: MentionsSearchEventsQuery) => {
      const now = DateTime.now().setZone(timeZone);
      const newEvents = parseCalendarEventsForMentionsGQL(
        data,
        [weekStartDateThisWeek, weekStartDateNextWeek],
        now,
        timeZone,
      );

      const newUpcomingEvents = parseUpcomingEvents(data, now, timeZone).slice(0, 4);

      setEvents(newEvents);
      setUpcomingEvents(newUpcomingEvents);
    },
    [weekStartDateThisWeek, weekStartDateNextWeek],
  );

  const fuse = new Fuse(events, { keys: ["title"], threshold: 0.3 });

  const { loading } = useQuery(MentionsSearchEventsDocument, {
    skip: !orgId || !weekStartDateThisWeek || !weekStartDateNextWeek || !calendarId,
    variables: {
      orgId: orgId as string,
      weekStartDates: [weekStartDateThisWeek, weekStartDateNextWeek],
      timeZone,
      calendarIds: [calendarId as string],
      includeAllDay: true,
    },
    onCompleted: parseAndAddEvents,
    /*
      NB: Known Apollo bug where refetches don't trigger onCompleted callbacks
      Setting notifyOnNetworkStatusChange to true is a workaround to force component re-render
      Could not use useEffect since returned data is cached version due to missing IDs on EventParent
      */
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all", // This allows us to receive both error and data to able to load partial data.
  });

  const handleEventSearch = useCallback(
    (input: string) => {
      if (!loading && !isEmpty(events)) {
        const results = fuse.search(input);
        const mappedResults = results.map((result) => result.item).slice(0, 5);
        setSearchEvents(mappedResults);
      } else {
        setSearchEvents([]);
      }
    },
    [loading, events, setSearchEvents],
  );

  const debouncedHandleEventSearch = debounce(handleEventSearch, 150);

  const resetSearchEvents = () => {
    setSearchEvents([]);
    setShowUpcomingEvents(false);
  };

  return {
    handleEventSearch: debouncedHandleEventSearch,
    resetSearchEvents,
    searchEvents,
    setShowEventSearchEmptyState,
  };
};
