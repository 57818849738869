import usePlannerMetaData from "#webapp/src/components/web-app-calendar/hooks/usePlannerMetaData";
import { IReduxState } from "#webapp/src/state/reducers/root.reducer";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { GetNameFromCalendarIdDocument } from "../__generated__/GetNameFromCalendarIds.v2.generated";

export type CalendarIdToNamesMap = Record<string, { firstName: string; displayName: string }>;
export const useSelectedCalendarNames = () => {
  const { userCalendarIds, teamCalendarIds } = usePlannerMetaData();

  const { calendarIdsToFullyShow } = useSelector((state: IReduxState) => state.multiCalendar);

  const otherCalendarIds = calendarIdsToFullyShow.filter(
    (calendarId) => !userCalendarIds.includes(calendarId) && !teamCalendarIds.includes(calendarId),
  );

  const { data, loading } = useGatewayQuery(GetNameFromCalendarIdDocument, {
    variables: {
      calendarIds: otherCalendarIds,
    },
    skip: isEmpty(otherCalendarIds),
  });

  const calendarIdToNamesMap: CalendarIdToNamesMap = {};

  data?.members.forEach((member) => {
    if (
      member?.person &&
      member.person.email &&
      member.person.givenName &&
      member.person.displayName
    ) {
      calendarIdToNamesMap[member.person.email] = {
        firstName: member?.person.givenName,
        displayName: member?.person.displayName,
      };
    }
  });

  return { calendarIdToNamesMap, loading };
};
