import { useCallback, useEffect, useState } from "react";

type Props = {
  active: boolean;
  inputRef: React.RefObject<HTMLDivElement>;
  onSelect: (index: number) => void;
  optionCount: number;
  preventWrapAround?: boolean;
};

export const useKeyboardSelect = ({
  active,
  inputRef,
  onSelect,
  optionCount,
  preventWrapAround = false,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.code) {
        case "Enter":
          onSelect(activeIndex);
          event.preventDefault();
          event.stopPropagation();
          break;
        case "ArrowDown":
          if (preventWrapAround && activeIndex === optionCount - 1) {
            return;
          }
          setActiveIndex((prev) => (prev + 1) % optionCount);
          event.preventDefault();
          event.stopPropagation();
          break;
        case "ArrowUp":
          if (preventWrapAround && activeIndex === 0) {
            return;
          }
          setActiveIndex((prev) => (prev <= 0 ? optionCount - 1 : (prev - 1) % optionCount));
          event.preventDefault();
          event.stopPropagation();
          break;
        default:
          break;
      }
    },
    [activeIndex, onSelect, optionCount],
  );

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      if (active) {
        input.addEventListener("keydown", handleKeyDown);
      } else {
        input.removeEventListener("keydown", handleKeyDown);
      }
    }

    return () => {
      if (input) {
        input.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [active, handleKeyDown, inputRef]);

  return { activeIndex, setActiveIndex };
};
