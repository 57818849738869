import { Attendee, ResponseStatusEnum } from "@clockwise/schema";

export type ObjectWithAttendees = {
  attendees?: Attendee[];
};

export const getAttendeeResponseStatusFromEvent = (
  event: ObjectWithAttendees,
  calendarId: string | null,
): ResponseStatusEnum | undefined => {
  const responseStatus = event.attendees?.find((attendee) => calendarId === attendee.urnValue)
    ?.responseStatus;

  return responseStatus || undefined;
};
