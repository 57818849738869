import React, { useEffect } from "react";
import { putCaretAtEndOfInput } from "../../../util/chatInput.util";
import {
  useReadSuggestedPrompt,
  useUpdateSuggestedPrompt,
} from "../../hooks/SuggestedPromptContext";

/**
 * Hook for injecting text into the chat input from NUX or other sources.
 */
export const useSuggestedInputText = ({
  disabled = false,
  ref,
  onChange,
  keyboardShortcutsEnabled = false,
}: {
  disabled?: boolean;
  ref: React.RefObject<HTMLDivElement>;
  onChange?: () => void;
  keyboardShortcutsEnabled?: boolean;
}) => {
  const suggestedPrompt = useReadSuggestedPrompt();
  const updateSuggestedPromptText = useUpdateSuggestedPrompt();

  useEffect(() => {
    if (
      !disabled &&
      !!suggestedPrompt?.promptText &&
      !!ref.current &&
      (keyboardShortcutsEnabled || suggestedPrompt.promptSource === "button")
    ) {
      const textNode = document.createTextNode(suggestedPrompt.promptText);
      ref.current.replaceChildren(textNode);
      putCaretAtEndOfInput(ref.current);

      onChange?.();

      updateSuggestedPromptText(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, suggestedPrompt?.promptText, suggestedPrompt?.promptSource]);
};
