import { Loader } from "@clockwise/design-system/src/components/Loader";
import { CheckCircleFilled } from "@clockwise/icons";
import React, { useEffect, useState } from "react";

const loadingMessages = [
  "Understanding request",
  "Gathering your preferences",
  "Scanning your calendar",
];

const MESSAGE_INTERVAL = 3000;
const LOADING_INTERVAL = 2500;

export const ChatInputLoading = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState<string>(
    loadingMessages[currentMessageIndex],
  );
  const messageSize = loadingMessages.length;
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(true);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      return increaseIntervalAndShowLoadingIcon();
    }, MESSAGE_INTERVAL);

    const firstCheckmark = setTimeout(() => {
      setShowLoadingIcon(false);
    }, LOADING_INTERVAL);

    const secondCheckmark = setTimeout(() => {
      setShowLoadingIcon(false);
    }, MESSAGE_INTERVAL + LOADING_INTERVAL);

    return () => {
      clearInterval(messageInterval);
      clearTimeout(firstCheckmark);
      clearTimeout(secondCheckmark);
    };
  }, []);

  const increaseIntervalAndShowLoadingIcon = () => {
    if (currentMessageIndex < messageSize - 1) {
      setCurrentMessageIndex((index) => index + 1);
      setShowLoadingIcon(true);
    }
  };

  useEffect(() => {
    if (currentMessageIndex < messageSize) {
      setCurrentMessage(loadingMessages[currentMessageIndex]);
    }
  }, [currentMessageIndex]);

  return (
    <div className="cw-body-sm cw-cursor-default cw-flex cw-items-center">
      {showLoadingIcon ? (
        <Loader size={14} sentiment="positive" />
      ) : (
        <CheckCircleFilled className="cw-w-[14px] cw-h-[14px] cw-text-positive-muted" />
      )}
      <div className="cw-text-muted cw-ml-2 cw-select-none">{currentMessage}</div>
    </div>
  );
};
